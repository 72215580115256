import React, { useContext, useEffect } from 'react'
import { AppRoutes } from 'AppRoutes'
import { StripeProvider } from 'services/providers/StripeContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import ThemeContext from 'services/providers/ThemeContext'
import 'assets/css/tailwind.css'
import './assets/css/fonts.css'
import { FilterProvider } from 'services/providers/FilterContext'
import { TableSearchProvider } from 'services/providers/TableSearchContext'
// Create a react-query client
const queryClient = new QueryClient()

export const App = () => {
  // do app scope stuff here e.g useEffect etc.
  const { lightMode } = useContext(ThemeContext)
  useEffect(() => {
    const htmlElement = document.documentElement
    if (lightMode) {
      htmlElement.classList.remove('dark')
    } else {
      htmlElement.classList.add('dark')
    }
  }, [lightMode])

  return (
    <TableSearchProvider>
      <FilterProvider>
        <QueryClientProvider client={queryClient}>
          <StripeProvider>
            <AppRoutes />
          </StripeProvider>
        </QueryClientProvider>
      </FilterProvider>
    </TableSearchProvider>
  )
}
