import React, { useState, useEffect, useContext } from 'react'
import { Check, PlusCircle, Search } from 'lucide-react'

import { cn } from 'services/Utils'
import { Badge } from 'shadcn-components/ui/badge'
import { Button } from 'shadcn-components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'shadcn-components/ui/popover'
import { Separator } from 'shadcn-components/ui/separator'
import FilterContext from 'services/providers/FilterContext'
import TableSearchContext from 'services/providers/TableSearchContext'

export function FacetedFilter({
  title,
  options,
  selectedValues,
  setSelectedValues,
  setQueryParam,
  filterKey,
  queryParam,
  tableSearchKey = 'default',
}) {
  const { updateFilterQueryParams } = useContext(FilterContext)
  const { searchQueryParams } = useContext(TableSearchContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [open, setOpen] = useState(false)
  const [tempSelectedValues, setTempSelectedValues] = useState(
    new Set(selectedValues)
  )

  useEffect(() => {
    setTempSelectedValues(new Set(selectedValues))
  }, [selectedValues])

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleOpenChange = (isOpen) => {
    setOpen(isOpen)
    if (!isOpen) {
      setTempSelectedValues(new Set(selectedValues))
    }
  }

  const handleApply = () => {
    setSelectedValues(tempSelectedValues)
    const selectedValuesArray = Array.from(tempSelectedValues)
    const newParams = {
      ...queryParam,
      ...(searchQueryParams[tableSearchKey] || {}),
    }

    if (selectedValuesArray.length > 0) {
      newParams[filterKey] = selectedValuesArray.join(',')
    } else {
      delete newParams[filterKey]
    }

    setQueryParam(newParams)
    updateFilterQueryParams({
      [filterKey]:
        selectedValuesArray.length > 0
          ? selectedValuesArray.join(',')
          : undefined,
    })
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="px-4 py-5 border-dashed">
          <PlusCircle className="mr-2 h-5 w-5" />
          {title}
          {selectedValues.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    variant="secondary"
                    className="rounded-sm px-1 font-normal"
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge
                        variant="secondary"
                        key={option.value}
                        className="rounded-sm px-1 font-normal"
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <div className="flex items-center border-b px-3 w-full">
          <Search className="mr-2 h-4 w-4 opacity-50" />
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`Search ${title.toLowerCase()}...`}
            className="flex h-9 bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground w-full"
            style={{ boxShadow: 'none' }}
          />
        </div>
        <div className="max-h-[300px] overflow-auto">
          {filteredOptions.length === 0 ? (
            <div className="p-2 text-sm text-muted-foreground">
              No results found.
            </div>
          ) : (
            filteredOptions.map((option) => {
              const isSelected = tempSelectedValues.has(option.value)
              return (
                <div
                  key={option.value}
                  className="flex items-center px-2 py-1.5 text-sm cursor-pointer hover:bg-accent"
                  onClick={() => {
                    const newSelectedValues = new Set(tempSelectedValues)
                    if (isSelected) {
                      newSelectedValues.delete(option.value)
                    } else {
                      newSelectedValues.add(option.value)
                    }
                    setTempSelectedValues(newSelectedValues)
                  }}
                >
                  <div
                    className={cn(
                      'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                      isSelected
                        ? 'bg-primary text-primary-foreground'
                        : 'opacity-50'
                    )}
                  >
                    {isSelected && <Check className="h-4 w-4" />}
                  </div>
                  <span>{option.label}</span>
                </div>
              )
            })
          )}
        </div>
        <div className="border-t p-2">
          <Button
            className="w-full justify-center text-center text-sm"
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
