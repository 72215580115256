// ##############################
// // // data for populating the calendar in Calendar view
// #############################

var today = new Date()
var y = today.getFullYear()
var m = today.getMonth()
var d = today.getDate()

const events = [
  {
    title: 'All Day Event',
    allDay: true,
    start: new Date(y, m, 1),
    end: new Date(y, m, 1),
  },
  {
    title: 'Meeting',
    start: new Date(y, m, d - 1, 10, 30),
    end: new Date(y, m, d - 1, 11, 30),
    allDay: false,
    color: 'green',
  },
  {
    title: 'Lunch',
    start: new Date(y, m, d + 7, 12, 0),
    end: new Date(y, m, d + 7, 14, 0),
    allDay: false,
    color: 'red',
  },
  {
    title: 'BD-PRO-REACT Launch',
    start: new Date(y, m, d - 2),
    end: new Date(y, m, d - 2),
    allDay: true,
    color: 'azure',
  },
  {
    title: 'Birthday Party',
    start: new Date(y, m, d + 1, 19, 0),
    end: new Date(y, m, d + 1, 22, 30),
    allDay: false,
    color: 'azure',
  },
  {
    title: 'Click for Autron',
    start: new Date(y, m, 21),
    end: new Date(y, m, 22),
    color: 'orange',
  },
  {
    title: 'Click for Google',
    start: new Date(y, m, 21),
    end: new Date(y, m, 22),
    color: 'orange',
  },
]

const GPSR_MARKETPLACES = [
  {
    id: 'A1RKKUPIHCS9HS',
    country: 'Spain',
    language: 'es_ES',
  },
  {
    id: 'A13V1IB3VIYZZH',
    country: 'France',
    language: 'fr_FR',
  },
  {
    id: 'AMEN7PMS3EDWL',
    country: 'Belgium',
    language: 'nl_BE',
  },
  {
    id: 'A1805IZSGTT6HS',
    country: 'Netherlands',
    language: 'nl_NL',
  },
  {
    id: 'A1PA6795UKMFR9',
    country: 'Germany',
    language: 'de_DE',
  },
  {
    id: 'APJ6JRA9NG5V4',
    country: 'Italy',
    language: 'it_IT',
  },
  {
    id: 'A2NODRKZP88ZB9',
    country: 'Sweden',
    language: 'sv_SE',
  },
  {
    id: 'A1C3SOZRARQ6R3',
    country: 'Poland',
    language: 'pl_PL',
  },
]

const documentTypes = [
  { label: 'Patient Fact Sheet', value: 'patient_fact_sheet' },
  { label: 'Provider Fact Sheet', value: 'provider_fact_sheet' },
  { label: 'Installation Manual', value: 'installation_manual' },
  { label: 'Instructions for Use', value: 'instructions_for_use' },
  { label: 'User Guide', value: 'user_guide' },
  { label: 'User Manual', value: 'user_manual' },
  { label: 'Safety Information', value: 'safety_information' },
]

export { events, GPSR_MARKETPLACES, documentTypes }
