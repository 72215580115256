import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

const url = 'gpsr-document'

const apiCompliance = new ApiCore({
  createSingle: true,
  updateSingle: true,
  deleteSingle: true,
  url: url,
  post: true,
})

apiCompliance.gpsr_documents = (id, params = {}, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/seller/${id}`, {
      params: params,
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiCompliance.gpsr_document_options = (id, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/seller/${id}/options`, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

export default apiCompliance
