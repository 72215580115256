import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { Button } from 'shadcn-components/ui/button'
import { Badge } from 'shadcn-components/ui/badge'
import { Pencil, Trash2 } from 'lucide-react'
import ProductTarget from './ProductTarget'
import apiProducts from 'services/api/Product'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { titleize, reactTableSortAndFilter } from 'services/Utils'
import CustomTable from 'components/CustomTable/CustomTable'
import { useToast } from 'shadcn-components/ui/use-toast'
import { Card, CardContent } from 'shadcn-components/ui/card'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'shadcn-components/ui/dropdown-menu'
import { MoreHorizontal } from 'lucide-react'

const ProductTargeting = ({ product }) => {
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const history = useHistory()

  const emptyProductTarget = {
    public_id: '',
    type: '',
    match_type: [
      {
        label: 'Exact',
        value: 'exact',
      },
      {
        label: 'Broad',
        value: 'broad',
      },
    ],
    target: '',
  }
  const [productTarget, setProductTarget] = useState(emptyProductTarget)
  const [showProductTargetForm, setShowProductTargetForm] = useState(false)

  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: 10,
  })
  const [productTargets, setProductTargets] = useState([])
  const [pages, setPages] = useState()
  const [total, setTotal] = useState()

  useEffect(() => {
    const [r, p, t] = reactTableSortAndFilter(
      product?.product_targets,
      queryParam
    )
    setProductTargets(r)
    setPages(p)
    setTotal(t)
  }, [product, queryParam])

  const deleteProductTarget = async (productTargetPublicId) => {
    // delete product target via api
    const response = await apiProducts
      .deleteSingleTarget(product.public_id, productTargetPublicId)
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/sellers/')
      toast({ variant: 'success', description: response?.data?.message })
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
    }
  }

  const productTargetColumns = useMemo(
    () => [
      {
        Header: 'type',
        accessor: 'type',
        sortable: true,
        Cell: (props) => titleize(props.value),
      },
      {
        Header: 'target',
        accessor: 'target',
        sortable: true,
        gfilter: 1,
      },
      {
        Header: 'match type',
        accessor: 'match_type',
        sortable: false,
        Cell: (props) =>
          props.value && ['keyword'].includes(props.row.original['type'])
            ? props.value.split(',').map((p) => (
                <Badge
                  key={p}
                  variant="outline"
                  className="border-yellow-300 bg-yellow-50 text-yellow-400 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200 mr-1 capitalize"
                >
                  {p}
                </Badge>
              ))
            : ['category'].includes(props.row.original['type']) &&
              props.value?.includes('|')
            ? props.value
            : null,
      },
      {
        Header: 'actions',
        accessor: 'actions',
        sortable: false,
        Cell: (props) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={(e) => editClick(props.row, e)}>
                <Pencil className="h-4 w-4 mr-2" />
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={(e) => {
                  e.stopPropagation()
                  deleteProductTarget(props.row.original['public_id'])
                }}
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ],
    []
  )

  const editClick = (row, e) => {
    setProductTarget({
      public_id: row.original['public_id'],
      type: {
        label: titleize(row.values['type']),
        value: row.values['type'],
      },
      match_type: row.values['match_type']
        ?.split(',')
        ?.map((mt) => ({ label: titleize(mt), value: mt })),
      target: row.values['target'],
    })
    setShowProductTargetForm(true)
  }

  return (
    <>
      <div className="space-y-4">
        {showProductTargetForm ? (
          <ProductTarget
            productTarget={productTarget}
            productPublicId={product.public_id}
            setShowComponent={setShowProductTargetForm}
          />
        ) : (
          <>
            <Card className="w-full">
              <CardContent className="p-6 space-y-4">
                <p className="text-muted-foreground text-sm mb-2">
                  Optionally add keywords, competitor products and category
                  targets to help Autron's AI learn faster. Start with less than
                  ten core keywords for each product. Misspellings and plurals
                  are not required.
                </p>
                {product.product_targets?.length > 0 && (
                  <CustomTable
                    columns={productTargetColumns}
                    data={productTargets}
                    loading={false}
                    total={total}
                    pageCount={pages}
                    queryParam={queryParam}
                    setQueryParam={setQueryParam}
                    onRowClick={(row, e) => editClick(row, e)}
                    tableSearchKey="product_targets"
                  />
                )}
                <div className="flex space-x-2">
                  <Button
                    onClick={() => {
                      setProductTarget(emptyProductTarget)
                      setShowProductTargetForm(true)
                    }}
                  >
                    Add Target
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => history.push('/admin/products')}
                    className="px-8"
                  >
                    Back
                  </Button>
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </div>
    </>
  )
}

export default ProductTargeting
