import React, { useState, useMemo } from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'shadcn-components/ui/dropdown-menu'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import { Badge } from 'shadcn-components/ui/badge'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from 'shadcn-components/ui/card'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'

import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import MultiSelect from 'shadcn-components/ui/multiselect'
import { Label } from 'shadcn-components/ui/label'
import { Input } from 'shadcn-components/ui/input'
import { Button } from 'shadcn-components/ui/button'
import { useToast } from 'shadcn-components/ui/use-toast'

import { InfoIcon, MoreHorizontal, Pencil, Trash2 } from 'lucide-react'
import CustomTable from 'components/CustomTable/CustomTable'
import apiCompliance from 'services/api/Compliance'
import { useQueryClient } from 'react-query'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'shadcn-components/ui/dialog'
import { GPSR_MARKETPLACES, documentTypes } from 'variables/general'
export const docTypes = documentTypes
export const marketplaces = GPSR_MARKETPLACES.map((item) => ({
  value: item.id,
  label: item.country,
}))

const Documents = ({
  seller,
  gpsrDocuments,
  documentTypes,
  marketplaces,
  loading,
  total,
  pageCount,
  queryParam,
  setQueryParam,
  tableLoading,
}) => {
  const queryClient = useQueryClient()
  const [loadingAction, setLoadingAction] = useState(false)
  const { toast } = useToast()
  const [primarySellerPublicId, setPrimarySellerPublicId] = useState(
    seller?.public_id
  )
  const [isAddDocumentView, setIsAddDocumentView] = useState(false)
  const [isEditDocumentView, setIsEditDocumentView] = useState(false)
  const [editPublicId, setEditPublicId] = useState('')
  const [selectedMarketplace, setSelectedMarketplace] = useState([])
  const [selectedDocumentType, setSelectedDocumentType] = useState('')
  const [uploadDocument, setUploadDocument] = useState(null)
  const [errors, setErrors] = useState({})
  const [currentFileName, setCurrentFileName] = useState('')
  const [showFileInput, setShowFileInput] = useState(false)
  const [deleteDocumentWarningAlert, setDeleteDocumentWarningAlert] =
    useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const filterOptions = useMemo(
    () => ({
      type: {
        title: 'Document Type',
        options: documentTypes.map((type) => ({
          label: type.label,
          value: type.value,
        })),
      },
      marketplace: {
        title: 'Marketplace',
        options: marketplaces.map((market) => ({
          label: market.label,
          value: market.value,
        })),
      },
    }),
    [documentTypes, marketplaces]
  )

  const [filterStates, setFilterStates] = useState(
    Object.keys(filterOptions).reduce((acc, key) => {
      acc[key] = {
        values: new Set(),
        setValues: (newValues) => {
          if (typeof newValues === 'string') {
            setQueryParam((prev) => ({
              ...prev,
              [key]: newValues || undefined,
            }))
          } else {
            setFilterStates((prev) => ({
              ...prev,
              [key]: {
                ...prev[key],
                values: newValues,
              },
            }))
          }
        },
      }
      return acc
    }, {})
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Document Type',
        accessor: 'type',
        // gfilter: 2,
        sortable: true,
      },
      {
        Header: 'Marketplaces',
        accessor: 'marketplace',
        Cell: (props) => {
          const marketplaces = props.value.split(',') // Assuming the value is a comma-separated string
          return (
            <div className="max-w-60 space-x-1 space-y-1">
              {marketplaces.map((marketplace, index) => (
                <Badge
                  key={index}
                  variant="outline"
                  className="border-yellow-300 bg-yellow-50 text-yellow-400 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200"
                >
                  {marketplace.trim()}
                </Badge>
              ))}
            </div>
          )
        },
        sortable: false,
      },
      {
        Header: 'Filename',
        accessor: 'url',
        gfilter: 2,
        sortable: false,
        Cell: (props) => {
          const filename = props.value?.substring(
            props.value.lastIndexOf('/') + 1
          )
          return filename || 'No filename'
        },
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        sortable: true,
        Cell: (props) => {
          const date = new Date(props.value)
          return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // Use 12-hour format
          })
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        Cell: ({ row }) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
              // onSelect={() => setRowToEdit(row.original)}
              >
                <Pencil className="h-4 w-4 mr-2" />
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={(e) => {
                  e.stopPropagation()
                  deleteDocumentWarning(row.original.public_id)
                }}
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ],
    []
  )

  const validateInputs = () => {
    const validationErrors = {}

    if (!selectedDocumentType) {
      validationErrors.documentType = 'Document type is required.'
    }

    if (!selectedMarketplace.length) {
      validationErrors.marketplaces = 'At least one marketplace is required.'
    }

    if (!uploadDocument && !isEditDocumentView) {
      validationErrors.uploadDocument = 'Document is required.'
    }

    setErrors(validationErrors)

    return Object.keys(validationErrors).length === 0
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png']
      const maxSizeInMB = 10
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024

      if (!allowedTypes.includes(file.type)) {
        alert('Invalid file type. Please upload a .pdf, .jpeg, or .png file.')
        event.target.value = ''
        return
      }

      if (file.size > maxSizeInBytes) {
        alert(`File is too large. Maximum size is ${maxSizeInMB} MB.`)
        event.target.value = ''
        return
      }

      setUploadDocument(file)
    }
  }

  const handleSaveDocument = async (e) => {
    e.preventDefault()

    if (!validateInputs()) {
      return
    }
    setLoadingAction(true)
    // Convert the file to Base64
    const encodeToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result.split(',')[1]) // Get the Base64 string without metadata
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    }

    let base64Document = null
    if (uploadDocument) {
      base64Document = await encodeToBase64(uploadDocument)
    }

    const newDocument = {
      seller_id: primarySellerPublicId,
      type: selectedDocumentType,
      marketplace: selectedMarketplace,
      ...(isEditDocumentView
        ? {
            public_id: editPublicId,
            ...(base64Document
              ? {
                  uploadDocument: base64Document,
                  filename: `${seller?.selling_partner_id}/${seller?.primary_marketplace_id}/${uploadDocument.name}`,
                }
              : {}),
          }
        : {
            uploadDocument: base64Document,
            filename: `${seller?.selling_partner_id}/${seller?.primary_marketplace_id}/${uploadDocument.name}`,
          }),
      seller_partner_id: seller?.selling_partner_id,
    }

    let response
    if (isEditDocumentView) {
      response = await apiCompliance.updateSingle(editPublicId, newDocument)
    } else {
      response = await apiCompliance.createSingle(newDocument)
    }

    if (response && response.status < 300) {
      queryClient.invalidateQueries([
        'GET/gpsr-document/seller/',
        primarySellerPublicId,
        queryParam,
      ])
      queryClient.invalidateQueries([
        'GET/gpsr-document/seller/',
        primarySellerPublicId,
        '/options',
      ])
      toast({
        variant: 'success',
        description: response?.data?.message,
      })
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
    }

    setLoadingAction(false)
    resetFieldInputs()
    setIsAddDocumentView(false)
    setIsEditDocumentView(false)
  }

  const setRowToEdit = (data) => {
    const _marketplace = marketplaces.filter((item) =>
      data?.marketplace_value.includes(item.value)
    )
    const type = documentTypes.filter((item) => item.value === data?.type_value)
    resetFieldInputs()
    setIsEditDocumentView(true)
    setEditPublicId(data?.public_id)
    setSelectedDocumentType(type ? type[0].value : '')
    setSelectedMarketplace(_marketplace)
    const file_url = data?.url
    setCurrentFileName(file_url.substring(file_url.lastIndexOf('/') + 1))
    setShowFileInput(false)
  }

  const resetFieldInputs = () => {
    setLoadingAction(false)
    setEditPublicId('')
    setSelectedMarketplace([])
    setSelectedDocumentType('')
    setUploadDocument(null)
    setErrors({})
  }

  // Add new function to handle "Select All" action
  const handleSelectAllMarketplaces = () => {
    setSelectedMarketplace(marketplaces)
  }

  // Create enhanced marketplaces array with "All" option
  const enhancedMarketplaces = useMemo(
    () => [
      {
        value: 'all',
        label: 'All',
        isSelectAll: true, // Special flag to identify this option
      },
      ...marketplaces,
    ],
    [marketplaces]
  )

  const deleteDocumentWarning = (documentPublicId) => {
    setDeleteDocumentWarningAlert(documentPublicId)
  }

  const deleteDocumentWarningConfirm = async (documentPublicId) => {
    setIsDeleting(true)
    // Call the delete API
    const response = await apiCompliance.deleteSingle(documentPublicId)
    if (response && response.status < 300) {
      queryClient.invalidateQueries([
        'GET/gpsr-document/seller/',
        primarySellerPublicId,
        queryParam,
      ])
      queryClient.invalidateQueries([
        'GET/gpsr-document/seller/',
        primarySellerPublicId,
        '/options',
      ])
      queryClient.invalidateQueries([
        'GET/sellers/',
        primarySellerPublicId,
        '/products-gpsr',
        queryParam,
      ])
      toast({ variant: 'success', description: response?.data?.message })
    } else {
      if (response) {
        toast({ variant: 'destructive', description: response?.data?.message })
      }
    }
    setDeleteDocumentWarningAlert(null)
    setIsDeleting(false)
  }

  const deleteDocumentWarningHideAlert = () => {
    setDeleteDocumentWarningAlert(null)
  }

  return (
    <>
      {deleteDocumentWarningAlert ? (
        <Dialog
          open={!!deleteDocumentWarningAlert}
          onOpenChange={deleteDocumentWarningHideAlert}
        >
          <DialogContent className="w-[380px] rounded-lg sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-space-grotesk font-normal flex items-center gap-2">
                Are you sure?
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="mb-4">
              <p className="font-regular">
                This action cannot be undone. Are you sure you want to delete
                this document? It will also be disassociated from your products.
              </p>
            </DialogDescription>
            <DialogFooter className="sm:justify-end">
              <Button
                variant="outline"
                onClick={deleteDocumentWarningHideAlert}
                className="mt-4 sm:mt-0"
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={() =>
                  deleteDocumentWarningConfirm(deleteDocumentWarningAlert)
                }
                disabled={isDeleting}
                className="min-w-[120px]"
              >
                {isDeleting ? <LoadingSpinner /> : 'Yes, delete it!'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : null}

      {tableLoading ? (
        <Card className="overflow-visible h-auto w-full">
          <CardContent className="pt-6 overflow-visible">
            <div className="space-y-4">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-3/4" />
              <Skeleton className="h-4 w-1/2" />
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
            </div>
          </CardContent>
        </Card>
      ) : isAddDocumentView || isEditDocumentView ? (
        <Card className="w-full lg:w-[700px]">
          <CardContent className="px-6 py-4 pb-8">
            <form onSubmit={handleSaveDocument}>
              <div className="space-y-4">
                <div className="flex flex-col">
                  <CardTitle className="text-2xl font-space-grotesk font-normal tracking-normal">
                    {isEditDocumentView ? 'Edit Document' : 'Add Document'}
                  </CardTitle>
                  <CardDescription className="font-normal">
                    Upload and categorise a compliance document.
                  </CardDescription>
                </div>

                <div className="flex flex-col gap-1">
                  <Label htmlFor="document-type-select">Document Type</Label>
                  <Select
                    value={selectedDocumentType}
                    onValueChange={(value) => {
                      setSelectedDocumentType(value)
                    }}
                  >
                    <SelectTrigger id="documentType">
                      <SelectValue placeholder="Select Document Type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {documentTypes.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {errors.documentType && (
                    <span className="text-sm text-red-400 mt-1">
                      {errors.documentType}
                    </span>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <Label htmlFor="marketplace-select">Marketplaces</Label>
                  <MultiSelect
                    options={enhancedMarketplaces}
                    selectedOptions={selectedMarketplace}
                    onOptionSelect={(option) => {
                      if (option.isSelectAll) {
                        handleSelectAllMarketplaces()
                      } else {
                        setSelectedMarketplace((prev) => [
                          ...(prev || []),
                          option,
                        ])
                      }
                    }}
                    onOptionUnselect={(option) =>
                      setSelectedMarketplace((prev) =>
                        (prev || []).filter((mp) => mp.value !== option.value)
                      )
                    }
                    placeholder="Select marketplaces..."
                  />
                  {errors.marketplaces && (
                    <span className="text-sm text-red-400 mt-1">
                      {errors.marketplaces}
                    </span>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <Label htmlFor="file-upload" className="flex items-center">
                    Upload Document
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <InfoIcon
                            className="h-4 w-4 ml-2 cursor-pointer"
                            strokeWidth={2.5}
                          />
                        </TooltipTrigger>
                        <TooltipContent className="w-60">
                          Maximum file size: 10 MB Supported formats: PDF, JPEG,
                          PNG
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </Label>

                  {isEditDocumentView && !showFileInput ? (
                    <div className="flex items-center justify-between p-2 border rounded-md">
                      <span className="truncate max-w-[400px]">
                        {currentFileName}
                      </span>
                      <Button
                        type="button"
                        variant="outline"
                        onClick={() => setShowFileInput(true)}
                      >
                        Replace
                      </Button>
                    </div>
                  ) : (
                    <Input
                      type="file"
                      accept=".pdf, .jpeg, .jpg, .png"
                      onChange={handleFileChange}
                      className="hover:cursor-pointer"
                    />
                  )}
                  {errors.uploadDocument && (
                    <span className="text-sm text-red-400 mt-1">
                      {errors.uploadDocument}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex justify-end mt-4 gap-4">
                <Button
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault()
                    setIsAddDocumentView(false)
                    setIsEditDocumentView(false)
                    resetFieldInputs()
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" className="min-w-20">
                  {loadingAction ? (
                    <LoadingSpinner className="w-5 h-5" />
                  ) : isEditDocumentView ? (
                    'Update'
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      ) : (
        <Card className="w-full">
          <CardContent className="px-6 py-4">
            <div className="mb-4">
              <CardTitle className="text-2xl font-space-grotesk font-normal tracking-normal">
                Documents
              </CardTitle>
              <CardDescription className="font-normal">
                Upload your product’s GPSR compliance documentation.
              </CardDescription>
            </div>
            <Button
              className="w-[150px] mb-4"
              onClick={() => setIsAddDocumentView(true)}
            >
              Add Document
            </Button>
            <CustomTable
              columns={columns}
              data={gpsrDocuments}
              loading={loading}
              total={total}
              pageCount={pageCount}
              queryParam={queryParam}
              setQueryParam={setQueryParam}
              onRowClick={(row, e) => {
                setRowToEdit(row?.original)
                // history.push(
                //   `/admin/compliance/documents/${row?.original?.public_id}`
                // )
              }}
              filterOptions={filterOptions}
              filterStates={filterStates}
              searchInputPlaceholder="Search by filename..."
              tableSearchKey="documents-search"
            />
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default Documents
