import React, { useState, useMemo, useEffect, useContext } from 'react'
import { inputFieldChange, sellerProfile } from 'services/Utils'
import apiAuth from 'services/api/Auth.js'
import apiUsers from 'services/api/User.js'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, Link } from 'react-router-dom'
import apiSellers from 'services/api/Seller'
import Billing from './Billing'
import { copyTextToClipboard, managedSellerInviteLink } from 'services/Utils'
import { DateTime } from 'luxon'
import { Separator } from 'shadcn-components/ui/separator'
import { Tabs, TabsContent } from 'shadcn-components/ui/tabs'
import { getCountries, getCountryCallingCode } from 'libphonenumber-js'

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  CardDescription,
} from 'shadcn-components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'shadcn-components/ui/dialog'
import { Input } from 'shadcn-components/ui/input'
import { Label } from 'shadcn-components/ui/label'
import { Button } from 'shadcn-components/ui/button'
import { useToast } from 'shadcn-components/ui/use-toast'
import {
  User,
  Building,
  Mail,
  Phone,
  PencilIcon,
  RefreshCcwIcon,
  TrashIcon,
  Pencil,
  AlertTriangle,
  Trash2,
} from 'lucide-react'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import CustomTable from 'components/CustomTable/CustomTable'
import ThemeContext from 'services/providers/ThemeContext'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'shadcn-components/ui/dropdown-menu'
import { MoreHorizontal } from 'lucide-react'
import { PhoneInput } from 'shadcn-components/ui/phone-input'
import parsePhoneNumber from 'libphonenumber-js'

const Account = () => {
  const { toast } = useToast()
  const { lightMode } = useContext(ThemeContext)
  const queryClient = useQueryClient()
  const { data: user, isFetching: isLoading } = useQuery(
    'GET/auth/user',
    () => apiAuth.user(),
    {
      onSuccess: (data) => {
        if (!firstname && !lastname && !company && !email && !phone) {
          setFirstname(data.firstname)
          setLastname(data.lastname)
          setCompany(data.company.name)
          setEmail(data.email)
          setPhone(data.phone)
          setPrimarySeller(data.primary_seller)
        }
      },
    }
  )

  useEffect(() => {
    if (user?.phone) {
      try {
        const parsedPhone = parsePhoneNumber(String(user.phone))
        setCountryCallingCode(parsedPhone?.countryCallingCode)
      } catch (error) {
        console.error('Error parsing phone number:', error)
      }
    }
  }, [user?.phone])

  const history = useHistory()

  const [accountTab, setAccountTab] = useState('')
  const [loading, setLoading] = useState(false)
  const [publicId] = useState(user.public_id)
  const [firstname, setFirstname] = useState(user.firstname)
  const [firstnameValidationState, setFirstnameValidationState] = useState(
    user.firstname ? 'has-success' : ''
  )
  const [lastname, setLastname] = useState(user.lastname)
  const [lastnameValidationState, setLastnameValidationState] = useState(
    user.lastname ? 'has-success' : ''
  )
  const [company, setCompany] = useState(user.company.name)
  const [companyValidationState, setCompanyValidationState] = useState(
    user.company ? 'has-success' : ''
  )
  const [email, setEmail] = useState(user.email)
  const [emailValidationState, setEmailValidationState] = useState(
    user.email ? 'has-success' : ''
  )
  const [phone, setPhone] = useState(user.phone)
  const [phoneValidationState, setPhoneValidationState] = useState(
    user.phone ? 'has-success' : ''
  )
  const [deleteSellerWarningAlert, setDeleteSellerWarningAlert] = useState(null)
  const [refreshSpinner, setRefreshSpinner] = useState(false)
  const [refreshSpinnerKey, setRefreshSpinnerKey] = useState()
  const [primarySeller, setPrimarySeller] = useState({})
  const [isCopied, setIsCopied] = useState(false)
  const [countryCallingCode, setCountryCallingCode] = useState(null)

  useEffect(() => {
    const tab = history.location.pathname.split('account/').pop().split('/')[0]
    if (tab === '') {
      setAccountTab('profile')
    } else {
      setAccountTab(tab)
    }
  }, [history.location.pathname])

  const saveClick = async (e) => {
    if (
      firstnameValidationState === 'has-success' &&
      lastnameValidationState === 'has-success' &&
      companyValidationState === 'has-success' &&
      emailValidationState === 'has-success' &&
      phoneValidationState === 'has-success'
    ) {
      setLoading(true)
      const user = {
        firstname: firstname,
        lastname: lastname,
        phone: phone,
      }
      // update user via api
      const response = await apiUsers
        .updateSingle(publicId, user)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/auth/user')
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
      }
      setLoading(false)
    } else {
      if (firstnameValidationState === '') {
        setFirstnameValidationState('has-danger')
      }
      if (lastnameValidationState === '') {
        setLastnameValidationState('has-danger')
      }
      if (companyValidationState === '') {
        setCompanyValidationState('has-danger')
      }
      if (emailValidationState === '') {
        setEmailValidationState('has-danger')
      }
      if (phoneValidationState === '') {
        setPhoneValidationState('has-danger')
      }
      e.preventDefault()
    }
  }

  const clickResetPassword = async (e) => {
    e.preventDefault()
    const response = await apiAuth
      .forgot_password(JSON.stringify({ email }))
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      toast({ variant: 'success', description: response?.data?.message })
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
      e.preventDefault()
    }
  }

  const deleteSellerWarning = (seller) => {
    setDeleteSellerWarningAlert(seller)
  }
  const deleteSellerWarningConfirm = async (seller) => {
    // delete seller via api
    const response = await apiSellers
      .deleteSingle(seller.public_id)
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/auth/user')
      toast({ variant: 'success', description: response?.data?.message })
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
    }
    setDeleteSellerWarningAlert(null)
  }
  const deleteSellerWarningHideAlert = () => {
    setDeleteSellerWarningAlert(null)
  }

  const clickRefreshSeller = async (e, seller, k) => {
    setRefreshSpinner(true)
    setRefreshSpinnerKey(k)
    const response = await apiSellers
      .refresh(seller.public_id, {})
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      toast({ variant: 'success', description: response?.data?.message })
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
      e.preventDefault()
    }
    setRefreshSpinner(false)
    setRefreshSpinnerKey(null)
  }

  const clickEditSeller = async (e, seller) => {
    if (seller.public_id === primarySeller.public_id) {
      history.push('/admin/onboarding/seller/' + seller.public_id)
    } else {
      const response = await apiSellers
        .primary(seller.public_id, seller)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
        window.location.replace('/admin/onboarding/seller/' + seller.public_id) // ideally we would handle this with invalidateQueries
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
        e.preventDefault()
      }
    }
  }

  const sellerCols = (bActions, bDate) => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        sortable: false,
      },
      {
        Header: 'Region',
        accessor: 'region',
        sortable: false,
      },
      {
        Header: 'Selling Partner',
        accessor: 'sp',
        sortable: false,
      },
      {
        Header: 'Amazon Ads',
        accessor: 'ads',
        sortable: false,
      },
      ...(bActions
        ? [
            {
              Header: 'Actions',
              accessor: 'actions',
              sortable: false,
            },
          ]
        : []),
      ...(bDate
        ? [
            {
              Header: 'Date Connected',
              accessor: 'ingest_completed_at',
              sortable: false,
              Cell: (props) =>
                props.value
                  ? new DateTime.fromISO(props.value)
                      .setLocale('en-US')
                      .toLocaleString(DateTime.DATE_MED)
                  : null,
            },
          ]
        : []),
    ]
  }

  const sellerColumns = useMemo(() => sellerCols(true, false), [])
  const managedSellerColumns = useMemo(() => sellerCols(false, true), [])

  const sellerData = (sellers) =>
    sellers.map((seller, key) => {
      return {
        id: key,
        name: seller.name,
        region: seller.marketplace_region,
        sp: seller?.spapi_access ? 'Connected' : 'Not Connected',
        ads: sellerProfile(seller)?.adapi_access
          ? 'Connected'
          : 'Not Connected',
        actions: (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={(e) => clickEditSeller(e, sellers[key])}
                >
                  <Pencil className="h-4 w-4 mr-2" />
                  Edit
                </DropdownMenuItem>
                {sellers[key]?.spapi_access && (
                  <DropdownMenuItem
                    onClick={(e) => clickRefreshSeller(e, sellers[key], key)}
                  >
                    <RefreshCcwIcon className="h-4 w-4 mr-2" />
                    Refresh
                  </DropdownMenuItem>
                )}
                {!sellers[key].spapi_access && (
                  <DropdownMenuItem
                    onClick={(e) => deleteSellerWarning(sellers[key])}
                  >
                    <Trash2 className="h-4 w-4" />
                    <span className="ml-2">Delete</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        ),
        ingest_completed_at: seller?.ingest_completed_at,
      }
    })

  const connectAccountClick = async (e) => {
    history.push('/admin/onboarding/seller/')
  }

  function getCountryFromCallingCode(callingCode) {
    const countries = getCountries()

    for (const country of countries) {
      if (getCountryCallingCode(country) === callingCode?.toString()) {
        return country
      }
    }

    return null
  }
  return (
    <>
      {deleteSellerWarningAlert ? (
        <>
          <Dialog open={deleteSellerWarningAlert ? true : false}>
            <DialogContent className="w-[380px] rounded-lg sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle className="text-xl font-space-grotesk font-normal flex items-center gap-2">
                  Are you sure?
                </DialogTitle>
              </DialogHeader>
              <DialogDescription className="mb-4">
                <p className="font-regular">
                  Deleting Amazon seller account{' '}
                  <span className="font-bold">
                    {deleteSellerWarningAlert?.name}
                  </span>{' '}
                  cannot be undone.
                </p>
              </DialogDescription>
              <DialogFooter className="sm:justify-end">
                <Button
                  variant="outline"
                  onClick={() => setDeleteSellerWarningAlert(null)}
                  className="mt-4 sm:mt-0"
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={() =>
                    deleteSellerWarningConfirm(deleteSellerWarningAlert)
                  }
                >
                  Yes, delete it!
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <></>
      )}
      <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto">
        <div className="flex justify-between items-start mb-6">
          <div className="flex flex-col gap-1">
            <h2
              className={`text-3xl tracking-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              Account
            </h2>
            <p className="text-muted-foreground font-geist ">
              Manage your account settings and preferences.
            </p>
          </div>
        </div>
        <Separator className="my-6" />
        <div className="flex-1">
          <Tabs
            value={'account-' + accountTab}
            className="flex flex-col sm:flex-row"
          >
            <nav className="space-y-1 w-full sm:w-1/4 flex flex-col">
              <Link
                className={`w-full text-left px-3 py-2 rounded-md text-sm font-geist font-medium tracking-normal ${
                  'account-' + accountTab === 'account-profile'
                    ? 'bg-muted'
                    : 'hover:bg-muted/50'
                }`}
                to="/admin/account"
              >
                Profile
              </Link>
              <Link
                className={`w-full text-left px-3 py-2 rounded-md text-sm font-geist font-medium tracking-normal ${
                  'account-' + accountTab === 'account-billing'
                    ? 'bg-muted'
                    : 'hover:bg-muted/50'
                }`}
                to="/admin/account/billing"
              >
                Billing
              </Link>
              <Link
                className={`w-full text-left px-3 py-2 rounded-md text-sm font-geist font-medium tracking-normal ${
                  'account-' + accountTab === 'account-amazon-settings'
                    ? 'bg-muted'
                    : 'hover:bg-muted/50'
                }`}
                to="/admin/account/amazon-settings"
              >
                Amazon Settings
              </Link>
            </nav>
            <div className="flex-1 sm:ml-6 mt-6 sm:mt-0">
              <TabsContent value="account-profile" className="mt-0">
                <Card className="pt-0 pb-0 border-none shadow-none">
                  <CardHeader className="pt-0">
                    <CardTitle className="tracking-normal font-space-grotesk font-normal">
                      Profile
                    </CardTitle>
                    <CardDescription className="font-normal">
                      This is how others will see you on the site.
                    </CardDescription>
                  </CardHeader>
                  <div className="flex flex-col gap-5">
                    <CardContent className="pt-0 pb-0">
                      <form className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor="firstname">First Name</Label>
                            <div className="relative">
                              <User className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                              <Input
                                name="firstname"
                                type="text"
                                defaultValue={firstname}
                                onChange={(e) =>
                                  inputFieldChange(
                                    e,
                                    setFirstname,
                                    setFirstnameValidationState,
                                    'length',
                                    1
                                  )
                                }
                                className="pl-8"
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor="lastname">Last Name</Label>
                            <div className="relative">
                              <User className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                              <Input
                                name="lastname"
                                type="text"
                                defaultValue={lastname}
                                onChange={(e) =>
                                  inputFieldChange(
                                    e,
                                    setLastname,
                                    setLastnameValidationState,
                                    'length',
                                    1
                                  )
                                }
                                className="pl-8"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor="company">Company</Label>
                          <div className="relative">
                            <Building className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                              disabled
                              name="company"
                              type="text"
                              defaultValue={company}
                              onChange={(e) =>
                                inputFieldChange(
                                  e,
                                  setCompany,
                                  setCompanyValidationState,
                                  'length',
                                  1
                                )
                              }
                              className="pl-8"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor="email">Email</Label>
                          <div className="relative">
                            <Mail className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                              name="email"
                              type="email"
                              defaultValue={email}
                              onChange={(e) =>
                                inputFieldChange(
                                  e,
                                  setEmail,
                                  setEmailValidationState,
                                  'email'
                                )
                              }
                              className="pl-8"
                              required={true}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor="phone">Phone</Label>
                          <PhoneInput
                            value={phone}
                            onChange={(value) =>
                              inputFieldChange(
                                value,
                                setPhone,
                                setPhoneValidationState,
                                'phone',
                                7
                              )
                            }
                            defaultCountry={
                              countryCallingCode === '1'
                                ? 'US'
                                : getCountryFromCallingCode(countryCallingCode)
                            }
                          />
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor="password">Password</Label>
                          <Button
                            variant="link"
                            className={`underline ${
                              lightMode ? 'text-slate-800' : 'text-white'
                            }`}
                            onClick={(e) => clickResetPassword(e)}
                          >
                            Reset your password
                          </Button>
                        </div>
                      </form>
                    </CardContent>
                    <CardFooter>
                      <Button
                        type="submit"
                        disabled={loading}
                        onClick={(e) => saveClick(e)}
                        className="w-32"
                      >
                        {loading ? (
                          <LoadingSpinner className="w-4 h-4" />
                        ) : (
                          'Save Settings'
                        )}
                      </Button>
                    </CardFooter>
                  </div>
                </Card>
              </TabsContent>
              <TabsContent value="account-billing">
                <Billing user={user} />
              </TabsContent>
              <TabsContent value="account-amazon-settings" className="mt-0">
                <Card className="border-none shadow-none">
                  <CardHeader className="pt-0">
                    <CardTitle className="tracking-normal font-space-grotesk font-normal">
                      Amazon Seller Accounts
                    </CardTitle>
                  </CardHeader>
                  {user.company.sellers.length > 0 ? (
                    <CardContent>
                      <CustomTable
                        columns={sellerColumns}
                        data={sellerData(user.company.sellers)}
                        loading={user ? false : true}
                        total={user.company.sellers.length}
                        tableSearchKey="account_sellers"
                      />
                    </CardContent>
                  ) : null}
                  <CardFooter>
                    {user.company.sellers.length > 0 ? (
                      <div>
                        <Button
                          className="btn-wd btn-just-icon"
                          color="primary"
                          onClick={(e) => connectAccountClick(e)}
                        >
                          Add New
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <CardDescription className="mb-3">
                          Get started by connecting your Amazon account.
                        </CardDescription>
                        <Button
                          className="btn-wd btn-round btn-just-icon"
                          color="primary"
                          onClick={(e) => connectAccountClick(e)}
                        >
                          Connect Amazon Account
                        </Button>
                      </div>
                    )}
                  </CardFooter>
                </Card>
                <Card className="border-none shadow-none">
                  <CardHeader>
                    <CardTitle className="tracking-normal font-space-grotesk font-normal">
                      Managed Amazon Seller Accounts
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    {user.company.managed_sellers.length > 0 ? (
                      <CustomTable
                        columns={managedSellerColumns}
                        data={sellerData(user.company.managed_sellers)}
                        loading={user ? false : true}
                        total={user.company.managed_sellers.length}
                        tableSearchKey="account_managed_sellers"
                      />
                    ) : null}
                  </CardContent>
                  <CardFooter>
                    {user.company.managed_sellers.length > 0 ? (
                      <div>
                        <Button
                          className="btn-wd btn-just-icon btn-simple"
                          color="primary"
                          onClick={(e) => {
                            copyTextToClipboard(
                              managedSellerInviteLink(
                                user.company.public_id,
                                user.company.name
                              )
                            )
                            setIsCopied(true)
                          }}
                        >
                          Copy Seller Invite Link
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <p className="mb-3">
                          Looking to manage someone else's Amazon Ad account
                          using Autron?
                        </p>
                        <Button
                          className="btn-wd btn-round btn-just-icon btn-simple"
                          color="primary"
                          onClick={(e) => {
                            copyTextToClipboard(
                              managedSellerInviteLink(
                                user.company.public_id,
                                user.company.name
                              )
                            )
                            setIsCopied(true)
                          }}
                        >
                          Copy Invite Link
                        </Button>
                        {/* <span>{isCopied ? ' Copied!' : null}</span> */}
                      </div>
                    )}
                  </CardFooter>
                </Card>
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default Account
