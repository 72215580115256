import React, { useState, useMemo } from 'react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'
import { useToast } from 'shadcn-components/ui/use-toast'
import { Pencil, Trash2, HelpCircle } from 'lucide-react'
import { Button } from 'shadcn-components/ui/button'
import { Badge } from 'shadcn-components/ui/badge'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'shadcn-components/ui/dialog'
import { DateTime } from 'luxon'
import moment from 'moment-timezone'
import ProductCost from './ProductCost'
import apiProducts from 'services/api/Product'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import CustomTable from 'components/CustomTable/CustomTable'
import { Card, CardContent } from 'shadcn-components/ui/card'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'shadcn-components/ui/dropdown-menu'
import { MoreHorizontal } from 'lucide-react'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'

const ProductCosts = ({ product }) => {
  const today = new moment()
    .tz(product.marketplace_timezone)
    .startOf('day')
    .format()
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const history = useHistory()

  const emptyProductCost = {
    public_id: '',
    cost_of_goods_per_unit: '',
    shipping_cost_per_unit: '',
    misc_cost_per_unit: '',
    total_cost_per_unit: '',
    effective_from: today,
  }
  const [productCost, setProductCost] = useState(emptyProductCost)
  const [showProductCostForm, setShowProductCostForm] = useState(false)
  const [deleteProductCostWarningAlert, setDeleteProductCostWarningAlert] =
    useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteProductCostWarning = (productCostPublicId) => {
    setDeleteProductCostWarningAlert(productCostPublicId)
  }
  const deleteProductCostWarningConfirm = async (productCostPublicId) => {
    setIsDeleting(true)
    // delete product cost via api
    const response = await apiProducts
      .deleteSingleCost(product.public_id, productCostPublicId)
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/sellers/')
      toast({ variant: 'success', description: response?.data?.message })
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
    }
    setDeleteProductCostWarningAlert(null)
    setIsDeleting(false)
  }
  const deleteProductCostWarningHideAlert = () => {
    setDeleteProductCostWarningAlert(null)
  }

  const productCostColumns = useMemo(
    () => [
      {
        Header: 'Cost of Goods',
        accessor: 'cost_of_goods_per_unit',
        sortable: false,
        Cell: (props) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(props.value),
      },
      {
        Header: 'Inbound Shipping',
        accessor: 'shipping_cost_per_unit',
        sortable: false,
        Cell: (props) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(props.value),
      },
      {
        Header: 'Miscellaneous Cost',
        accessor: 'misc_cost_per_unit',
        sortable: false,
        Cell: (props) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(props.value),
      },
      {
        Header: 'Total Cost',
        accessor: 'total_cost_per_unit',
        sortable: false,
        Cell: (props) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(props.value),
      },
      {
        Header: 'Effective from',
        accessor: 'effective_from',
        sortable: false,
        Cell: (props) =>
          new DateTime.fromISO(props.value)
            .setZone(product.marketplace_timezone)
            .setLocale('en-US')
            .toLocaleString(DateTime.DATE_MED),
      },
      {
        Header: 'Effective to',
        accessor: 'effective_to',
        sortable: false,
        Cell: (props) =>
          props.row.values['state'] === 'archived'
            ? new DateTime.fromISO(props.value)
                .setZone(product.marketplace_timezone)
                .setLocale('en-US')
                .toLocaleString(DateTime.DATE_MED)
            : 'Present',
      },
      {
        Header: (
          <div className="flex items-center">
            Status
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <HelpCircle className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  Both Current and Historical costs are used by the Autron Ad
                  Engine. For best performance, please only delete or edit costs
                  if they were made in error.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
        accessor: 'state',
        sortable: false,
        Cell: (props) => (
          <Badge
            variant="outline"
            className={`capitalize ${
              props.value === 'enabled'
                ? 'border-lime-300 bg-lime-50 text-lime-400 dark:border-lime-200 dark:bg-transparent dark:text-lime-300'
                : 'border-yellow-300 bg-yellow-50 text-yellow-400 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200'
            }`}
          >
            {props.value === 'enabled' ? 'current' : 'historical'}
          </Badge>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        Cell: (props) => (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={(e) => editClick(props.row, e)}>
                  <Pencil className="h-4 w-4 mr-2" />
                  Edit
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteProductCostWarning(props.row.original['public_id'])
                  }}
                >
                  <Trash2 className="h-4 w-4 mr-2" />
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        ),
      },
    ],
    []
  )

  const editClick = (row, e) => {
    setProductCost({
      public_id: row.original['public_id'], // note original
      cost_of_goods_per_unit: row.values['cost_of_goods_per_unit'] || 0,
      shipping_cost_per_unit: row.values['shipping_cost_per_unit'] || 0,
      misc_cost_per_unit: row.values['misc_cost_per_unit'] || 0,
      total_cost_per_unit: row.values['total_cost_per_unit'] || 0,
      effective_from: row.values['effective_from'],
    })
    setShowProductCostForm(true)
  }

  return (
    <>
      {deleteProductCostWarningAlert ? (
        <>
          <Dialog
            open={deleteProductCostWarningAlert ? true : false}
            onOpenChange={() => setDeleteProductCostWarningAlert(false)}
          >
            <DialogContent className="w-[380px] rounded-lg sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle className="text-xl font-space-grotesk font-normal flex items-center gap-2">
                  Are you sure?
                </DialogTitle>
              </DialogHeader>
              <DialogDescription className="mb-4">
                <p className="font-regular">
                  Both Current and Historical costs are used by the Autron Ad
                  Engine. For best performance, only delete costs if they were
                  made in error.
                </p>
              </DialogDescription>
              <DialogFooter className="sm:justify-end">
                <Button
                  variant="outline"
                  onClick={() => setDeleteProductCostWarningAlert(null)}
                  className="mt-4 sm:mt-0"
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={() =>
                    deleteProductCostWarningConfirm(
                      deleteProductCostWarningAlert
                    )
                  }
                  disabled={isDeleting}
                  className="min-w-[120px]"
                >
                  {isDeleting ? <LoadingSpinner /> : 'Yes, delete it!'}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <></>
      )}
      <div className="space-y-4">
        {showProductCostForm ? (
          <ProductCost
            productCost={productCost}
            productPublicId={product.public_id}
            currency={product.currency}
            timezone={product.marketplace_timezone}
            setShowComponent={setShowProductCostForm}
          />
        ) : (
          <>
            <Card className="w-full">
              <CardContent className="p-6 space-y-4">
                <p className="text-muted-foreground text-sm mb-2">
                  Input unit cost of goods and inbound shipping costs so Autron
                  can calculate profits and optimize Ad spend.
                </p>
                {product.product_costs?.length > 0 ? (
                  <CustomTable
                    columns={productCostColumns}
                    data={product.product_costs}
                    loading={false}
                    total={product.product_costs?.length}
                    onRowClick={(row, e) => editClick(row, e)}
                    tableSearchKey="product_costs"
                  />
                ) : null}
                <div className="flex space-x-2">
                  <Button
                    onClick={() => {
                      setProductCost(emptyProductCost)
                      setShowProductCostForm(true)
                    }}
                  >
                    Add Costs
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => history.push('/admin/products')}
                    className="px-8"
                  >
                    Back
                  </Button>
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </div>
    </>
  )
}

export default ProductCosts
