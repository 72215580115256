import React, { createContext, useState, useCallback } from 'react'

const FilterContext = createContext(null)

export const FilterProvider = ({ children }) => {
  const [filterQueryParams, setFilterQueryParams] = useState({})

  const updateFilterQueryParams = useCallback((newParams) => {
    setFilterQueryParams((prevParams) => ({
      ...prevParams,
      ...newParams,
    }))
  }, [])

  const clearFilterQueryParams = useCallback(() => {
    setFilterQueryParams({})
  }, [])

  return (
    <FilterContext.Provider
      value={{
        filterQueryParams,
        updateFilterQueryParams,
        clearFilterQueryParams,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export default FilterContext
