import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Card, CardContent } from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { Alert, AlertDescription } from 'shadcn-components/ui/alert'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'shadcn-components/ui/dropdown-menu'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import apiSellers from 'services/api/Seller'
import Product from './Product'
import ProductsImport from './ProductsImport'
import { Pencil, ChevronDown } from 'lucide-react'
import CustomTable from 'components/CustomTable/CustomTable'
import ThemeContext from 'services/providers/ThemeContext'

const Products = ({ user }) => {
  const { lightMode } = useContext(ThemeContext)
  const params = useParams()
  const [product, setProduct] = useState()
  const [primarySeller, setPrimarySeller] = useState({})
  const primarySellerPublicId = primarySeller?.public_id
  const [products, setProducts] = useState([])
  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: 10,
  })
  const [gfilterQuery, setGfilterQuery] = useState(false)
  const [pages, setPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const history = useHistory()
  const queryClient = useQueryClient()

  const [importType, setImportType] = useState(null)

  const importTypes = [
    {
      options: { value: 'costs', label: 'Product costs' },
      fields: [
        'asin',
        'cost_of_goods_per_unit',
        'shipping_cost_per_unit',
        'misc_cost_per_unit',
      ],
      exampleFileUrl:
        'https://docs.google.com/spreadsheets/d/1sZ0ISVFlxgkLL8ZQIrMPzixS9DXOGAO-CHt_k3ylDao/edit?usp=sharing',
    },
    {
      options: { value: 'targets', label: 'Product ad targeting' },
      fields: ['asin', 'type', 'match_type', 'target'],
      exampleFileUrl:
        'https://docs.google.com/spreadsheets/d/1KTga8XcKZiuHqcaf1H-LdAZsnCI1OoMY23klm509vEk/edit?usp=sharing',
    },
  ]

  useEffect(() => {
    setPrimarySeller(user.primary_seller)
  }, [user])

  const setProductStates = (pObj, param) => {
    setProducts(pObj?.data.products)
    setPages(pObj?.data.pages)
    setTotal(pObj?.data.total)
    setLoading(false)
    setProduct(
      pObj?.data.products?.find(
        (product) => product.public_id === params.public_id
      )
    )
    setGfilterQuery(
      Object.keys(param).some(function (k) {
        return ~k.indexOf('gfilter')
      })
    )
  }

  const {
    data: productsObject,
    isFetching: isLoading,
    productsRefetch,
  } = useQuery(
    ['GET/sellers/', primarySellerPublicId, '/products', queryParam],
    () => apiSellers.products(primarySellerPublicId, queryParam),
    {
      staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (productsObject) => {
        setProductStates(productsObject, queryParam)
      },
    }
  )

  useEffect(() => {
    const productsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/products',
      queryParam,
    ])
    if (productsCache && productsCache.data?.products) {
      setProductStates(productsCache, queryParam)
    }
  }, [queryClient, primarySellerPublicId, queryParam])

  useEffect(() => {
    setProduct(
      products?.find((product) => product.public_id === params.public_id)
    )
  }, [params.public_id]) // if add products then get 2 calls on refresh...

  const productColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'product_name',
        filter: 'ilike', // filter is used by react-table
        gfilter: 1, // custom param, number order needs to match what's expected in backend dto
        sortable: true,
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        filter: 'eq',
        gfilter: 2,
        sortable: true,
      },
      {
        Header: 'ASIN',
        accessor: 'asin',
        filter: 'eq',
        gfilter: 3,
        sortable: true,
      },
      {
        Header: 'Inventory',
        accessor: 'inventory_quantity',
        sortable: false,
      },
      {
        Header: 'Price',
        accessor: 'price',
        sortable: false,
      },
      {
        Header: 'Amazon Fee',
        accessor: 'estimated_fee_total',
        sortable: false,
      },
      {
        Header: 'Total Cost',
        accessor: 'total_cost',
        sortable: false,
      },
      {
        Header: 'Profit',
        accessor: 'profit',
        sortable: false,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
      },
    ],
    []
  )

  const totalCostAndProfit = (product) => {
    if (product?.product_costs?.length > 0) {
      return [
        <span>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(product.total_cost)}
        </span>,
        <span>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(product.profit)}
        </span>,
      ]
    } else {
      return [
        <Button
          variant="link"
          className="p-0 h-auto font-normal text-sm text-yellow-400 dark:text-yellow-200 dark:hover:text-yellow-300 dark:focus:text-yellow-300 hover:text-yellow-500 focus:text-yellow-500 focus:outline-none"
          onClick={() => {
            setProduct(product)
            history.push('/admin/products/' + product.public_id)
          }}
        >
          Set Costs
        </Button>,
        '',
      ]
    }
  }

  const productData = products?.map((product, key) => {
    let costAndProfit = totalCostAndProfit(product)
    let price = product.price || product.your_price
    let estimated_fee_total =
      product.estimated_fee_total || product.estimated_referral_fee_per_item
    let inventory_quantity =
      product.afn_fulfillable_quantity || product.quantity
    return {
      id: key,
      public_id: product.public_id,
      product_name: product.product_name.substring(0, 100) + '...',
      sku: product.sku,
      asin: product.asin,
      inventory_quantity: inventory_quantity,
      price: (
        <span>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(price)}
        </span>
      ),
      estimated_fee_total: (
        <span>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(estimated_fee_total)}
        </span>
      ),
      total_cost: costAndProfit[0],
      profit: costAndProfit[1],
      actions: (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="icon"
                onClick={() => {
                  setProduct(product)
                  history.push('/admin/products/' + product.public_id)
                }}
                variant="ghost"
              >
                <Pencil className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Edit</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ),
    }
  })

  return (
    <>
      <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto">
        <div className="flex flex-col items-start w-full">
          <div className="flex flex-col gap-1 mb-6 w-full">
            <h2
              className={`text-3xl tracking-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              Products
            </h2>
            <p className="text-muted-foreground font-geist tracking-normal">
              Your Amazon products. Where you can specify product costs and
              suggest advertising targets.
            </p>
          </div>
          {loading ? (
            <Card className="overflow-visible h-auto w-full">
              <CardContent className="pt-6 overflow-visible">
                <div className="space-y-4">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-3/4" />
                  <Skeleton className="h-4 w-1/2" />
                  <div className="grid grid-cols-3 gap-4 pt-4">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                  <div className="grid grid-cols-3 gap-4 pt-4">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                  <div className="grid grid-cols-3 gap-4 pt-4">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : product ? (
            <Product product={product} />
          ) : (
            <>
              {products?.length === 0 && !gfilterQuery ? (
                <Alert className="mt-4">
                  <AlertDescription>
                    <strong>No products found.</strong> If this is unexpected,
                    we might still be syncing your Amazon account, please check
                    back in a couple of hours.
                  </AlertDescription>
                </Alert>
              ) : (
                <>
                  {importType ? (
                    <ProductsImport
                      seller={primarySeller}
                      importType={importType}
                      setImportType={setImportType}
                      importTypes={importTypes}
                    />
                  ) : (
                    <Card className="mt-4 w-full">
                      <CardContent className="relative py-4 w-full">
                        {products?.length > 0 ||
                        (products?.length === 0 && gfilterQuery) ? (
                          <>
                            <div className="flex justify-end mb-4 absolute right-6">
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button
                                    variant="outline"
                                    className="flex items-center gap-2"
                                  >
                                    Bulk import
                                    <ChevronDown className="h-4 w-4" />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                  {importTypes.map((type, i) => (
                                    <DropdownMenuItem
                                      key={i}
                                      onSelect={() =>
                                        setImportType(type.options.value)
                                      }
                                    >
                                      {type.options.label}
                                    </DropdownMenuItem>
                                  ))}
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </div>
                            <CustomTable
                              columns={productColumns}
                              data={productData}
                              loading={isLoading}
                              total={total}
                              pageCount={pages}
                              queryParam={queryParam}
                              setQueryParam={setQueryParam}
                              onRowClick={(row, e) => {
                                history.push(
                                  '/admin/products/' + row.original['public_id']
                                )
                              }}
                              tableSearchKey="products"
                            />
                          </>
                        ) : null}
                      </CardContent>
                    </Card>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Products
