import React, { useState, useMemo, useCallback, useEffect } from 'react'

import { Skeleton } from 'shadcn-components/ui/skeleton'

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from 'shadcn-components/ui/card'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'shadcn-components/ui/dropdown-menu'

import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import MultiSelect from 'shadcn-components/ui/multiselect'
import { Label } from 'shadcn-components/ui/label'
import { Input } from 'shadcn-components/ui/input'
import { Button } from 'shadcn-components/ui/button'
import { useToast } from 'shadcn-components/ui/use-toast'

import { Badge } from 'shadcn-components/ui/badge'
import {
  ChevronDown,
  FileText,
  MoreHorizontal,
  Pencil,
  Trash2,
  Plus,
  FileWarning,
  InfoIcon,
} from 'lucide-react'

import CustomTable from 'components/CustomTable/CustomTable'
import apiProductGpsrDocument from 'services/api/ProductGpsrDocument'
import { useQueryClient } from 'react-query'
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from 'shadcn-components/ui/tooltip'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'shadcn-components/ui/dialog'
import { documentTypes } from 'variables/general'

const ManageProducts = ({
  seller,
  products,
  documents,
  loading,
  total,
  pageCount,
  queryParam,
  setQueryParam,
  setSelectedRow,
  selectedRows,
  setSelectedRows,
  tableLoading,
}) => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const [primarySellerPublicId, setPrimarySellerPublicId] = useState(
    seller?.public_id
  )
  const [asin, setAsin] = useState([])
  const [selectedAsin, setSelectedAsin] = useState([])
  const [gpsrDocuments, setGpsrDocuments] = useState([])
  const [selectedGpsrDocuments, setSelectedGpsrDocuments] = useState([])
  const [isAssociateProduct, setIsAssociateProduct] = useState(false)
  const [isEditAssociateProduct, setIsEditAssociateProduct] = useState(false)
  const [isBulkAssociate, setIsBulkAssociate] = useState(false)
  const [errors, setErrors] = useState({})
  const [loadingAction, setLoadingAction] = useState(false)
  const [deleteProductWarningAlert, setDeleteProductWarningAlert] =
    useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const bulkOptions = [
    { value: 'associate', label: ' Bulk Associate Documents' },
  ]

  const documentStatusOptions = [
    { label: 'Has Documents', value: 'true' },
    { label: 'No Documents', value: 'false' },
  ]

  const amazonStatusOptions = [
    {
      label: 'Not Submitted',
      value: 'PENDING',
      description:
        'The compliance documents will be submitted to Amazon by Autron within 24 hours.',
      className:
        'border-slate-300 bg-slate-50 text-slate-400 dark:border-slate-100 dark:bg-transparent dark:text-slate-200 hover:border-slate-300 hover:bg-slate-50 hover:text-slate-400 dark:hover:border-slate-100 dark:hover:bg-transparent dark:hover:text-slate-200',
    },
    {
      label: 'Submitted',
      value: 'ACCEPTED',
      description: 'The compliance documents have been submitted to Amazon.',
      className:
        'border-yellow-300 bg-yellow-50 text-yellow-400 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200 hover:border-yellow-300 hover:bg-yellow-50 hover:text-yellow-400 dark:hover:border-yellow-100 dark:hover:bg-transparent dark:hover:text-yellow-200',
    },
    {
      label: 'Approved',
      value: 'APPROVED',
      description: 'The compliance documents have been approved by Amazon.',
      className:
        'border-lime-300 bg-lime-50 text-lime-400 dark:border-lime-200 dark:bg-transparent dark:text-lime-300 hover:border-lime-300 hover:bg-lime-50 hover:text-lime-400 dark:hover:border-lime-200 dark:hover:bg-transparent dark:hover:text-lime-300',
    },
    {
      label: 'Invalid',
      value: 'INVALID',
      description:
        'The compliance documents have been rejected by Amazon, see Amazon Issue column for more details.',
      className:
        'border-red-300 bg-red-50 text-red-400 dark:border-red-400 dark:bg-transparent dark:text-red-400 hover:border-red-400 hover:bg-red-50 hover:text-red-400 dark:hover:border-red-400 dark:hover:bg-transparent dark:hover:text-red-400',
    },
  ]

  const filterOptions = useMemo(
    () => ({
      has_document: {
        title: 'Document Status',
        options: documentStatusOptions,
      },
      status_amazon: {
        title: 'Amazon Status',
        options: amazonStatusOptions,
      },
    }),
    []
  )

  const [filterStates, setFilterStates] = useState(
    Object.keys(filterOptions).reduce((acc, key) => {
      acc[key] = {
        values: new Set(),
        setValues: (newValues) => {
          if (typeof newValues === 'string') {
            setQueryParam((prev) => ({
              ...prev,
              [key]: newValues || undefined,
            }))
          } else {
            setFilterStates((prev) => ({
              ...prev,
              [key]: {
                ...prev[key],
                values: newValues,
              },
            }))
          }
        },
      }
      return acc
    }, {})
  )

  const validateInputs = () => {
    const validationErrors = {}

    if (!selectedAsin.length) {
      validationErrors.asin = 'At least one asin is required.'
    }

    if (!selectedGpsrDocuments.length) {
      validationErrors.gpsrDocument = 'At least one GPSR document is required.'
    }

    setErrors(validationErrors)

    return Object.keys(validationErrors).length === 0
  }

  const resetFieldInputs = () => {
    setLoadingAction(false)
    setSelectedAsin([])
    setSelectedGpsrDocuments([])
    setIsAssociateProduct(false)
    setIsBulkAssociate(false)
    setIsEditAssociateProduct(false)
    setErrors({})
  }

  const getGpsrDocs = () => {
    const gpsrDocs = documents.map((item) => {
      const filename = item?.url.split('/')[5]
      const lbl = `${item.type} | ${filename} | ${item.marketplace}`
      return {
        label: lbl,
        value: item?.public_id,
      }
    })
    return gpsrDocs
  }

  useEffect(() => {
    const gpsr = getGpsrDocs()
    setGpsrDocuments(gpsr)
  }, [])

  const handleProcessSelectedRows = useCallback(() => {
    resetFieldInputs()
    // const selectedProducts = mapProducts(selectedRows)
    const _asin = selectedRows.map((item) => {
      return {
        label: item?.asin,
        value: item?.public_id,
      }
    })
    setAsin(_asin)
    setSelectedAsin(_asin)
    setIsBulkAssociate(true)
    setIsAssociateProduct(true)
  }, [selectedRows])

  const setProductToAssociate = (data) => {
    if (data?.product_gpsr_document.length) {
      setRowToEdit(data)
    } else {
      resetFieldInputs()
      const _asin = [
        {
          label: data?.asin,
          value: data?.public_id,
        },
      ]

      setAsin(_asin)
      setSelectedAsin(_asin)
      setIsAssociateProduct(true)
    }
  }

  const handleBulkProductAssociation = useCallback(
    (e) => {
      if (e === 'associate') {
        handleProcessSelectedRows()
      }
    },
    [setProductToAssociate]
  )

  const handleProductAssociation = async (e) => {
    e.preventDefault()

    if (!validateInputs()) {
      return
    }

    setLoadingAction(true)
    let response
    if (isBulkAssociate) {
      const product_ids = selectedAsin.map((item) => item.value)
      const document_ids = selectedGpsrDocuments.map((item) => item.value)

      response = await apiProductGpsrDocument.bulk_associate_documents({
        product_ids,
        document_ids,
        seller_id: primarySellerPublicId,
      })

      // Invalidate queries after bulk association
      if (response && response.status < 300) {
        queryClient.invalidateQueries([
          'GET/sellers/',
          primarySellerPublicId,
          '/products-gpsr',
        ])
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
      }
    } else if (isEditAssociateProduct) {
      // Extract the selected product ID from the first selected ASIN
      const productId = selectedAsin?.[0]?.value
      if (!productId) {
        throw new Error('No productId found.')
      }

      // Retrieve the current product GPSR data from products array
      const currProductData = products.find(
        (item) => item.public_id === productId
      )

      if (!currProductData || !currProductData.product_gpsr_document) {
        throw new Error(
          `No product_gpsr_document found for productId: ${productId}`
        )
      }

      // Map current product GPSR documents into a simplified format
      const currProductGpsr = currProductData.product_gpsr_document.map(
        (doc) => ({
          public_id: doc.public_id,
          product_public_id: productId,
          gpsr_document_public_id: doc.gpsr_document_id,
        })
      )

      // Extract selected GPSR documents
      const selectedGpsrDocs = selectedGpsrDocuments.map((item) => item.value)

      // Build the updated GPSR list from selected documents
      const updatedGpsr = selectedGpsrDocs.map((docId) => ({
        public_id: '', // Initially empty, to be filled if a match is found
        product_public_id: productId,
        gpsr_document_public_id: docId,
      }))

      // Create a lookup map from current GPSR data
      const currentMap = new Map()
      for (const item of currProductGpsr) {
        const key = `${item.product_public_id}-${item.gpsr_document_public_id}`
        currentMap.set(key, item.public_id)
      }

      // Merge current and updated data
      const updatedNew = updatedGpsr.map((item) => {
        const key = `${item.product_public_id}-${item.gpsr_document_public_id}`
        return currentMap.has(key)
          ? { ...item, public_id: currentMap.get(key) }
          : item
      })
      response = await apiProductGpsrDocument.updateSingle(
        productId,
        updatedNew
      )
      if (response && response.status < 300) {
        queryClient.invalidateQueries([
          'GET/sellers/',
          primarySellerPublicId,
          '/products-gpsr',
          queryParam,
        ])
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
      }
    } else {
      const product_ids = selectedAsin.map((item) => item.value)
      const document_ids = selectedGpsrDocuments.map((item) => item.value)

      response = await apiProductGpsrDocument.associate_documents({
        product_ids,
        document_ids,
        seller_id: primarySellerPublicId,
      })
      if (response && response.status < 300) {
        queryClient.invalidateQueries([
          'GET/sellers/',
          primarySellerPublicId,
          '/products-gpsr',
          queryParam,
        ])
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
      }
    }
    // setLoadingAction(false)
    resetFieldInputs()
    setIsAssociateProduct(false)
    setIsBulkAssociate(false)
    setIsEditAssociateProduct(false)
  }

  const deleteProductWarning = (productPublicId) => {
    setDeleteProductWarningAlert(productPublicId)
  }

  const deleteProductWarningConfirm = async (productPublicId) => {
    setIsDeleting(true)
    // Call the delete API
    const response = await apiProductGpsrDocument.remove_association(
      productPublicId
    )
    if (response && response.status < 300) {
      queryClient.invalidateQueries([
        'GET/sellers/',
        primarySellerPublicId,
        '/products-gpsr',
        queryParam,
      ])
      toast({ variant: 'success', description: response?.data?.message })
    } else {
      if (response) {
        toast({ variant: 'destructive', description: response?.data?.message })
      }
    }
    setDeleteProductWarningAlert(null)
    setIsDeleting(false)
  }

  const deleteProductWarningHideAlert = () => {
    setDeleteProductWarningAlert(null)
  }

  const setRowToEdit = async (data) => {
    resetFieldInputs()
    const asin = [
      {
        label: data?.asin,
        value: data?.public_id,
      },
    ]

    setAsin(asin)
    setSelectedAsin(asin)

    const gpsr = getGpsrDocs()
    const gpsr_document = products.find(
      (item) => item.public_id === data.public_id
    )
    if (gpsr_document) {
      const gpsr_document_ids = gpsr_document?.product_gpsr_document.map(
        (item) => item.gpsr_document_id
      )
      const gpsr_documents = gpsr.filter((item) => {
        return gpsr_document_ids.includes(item.value)
      })
      setSelectedGpsrDocuments(gpsr_documents)
      setIsAssociateProduct(false)
      setIsEditAssociateProduct(true)
    }
  }

  const getBadgeAmazonStatusProps = (status, statusOptions) => {
    const statusOption = statusOptions.find((option) => option.value === status)
    return {
      label: statusOption ? statusOption.label : 'Unknown',
      className: statusOption
        ? statusOption.className
        : 'bg-neutral-500 text-white',
    }
  }

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => {
          const { indeterminate, ...toggleAllProps } =
            getToggleAllRowsSelectedProps()
          return (
            <div className="flex items-center space-x-2">
              <Input
                type="checkbox"
                {...toggleAllProps}
                indeterminate={(indeterminate ?? false).toString()}
                className="checked:accent-yellow-200 "
              />
            </div>
          )
        },
        Cell: ({ row }) => {
          const { indeterminate, ...rowProps } = row.getToggleRowSelectedProps()
          return (
            <div
              className="flex items-center space-x-2"
              onClick={(e) => e.stopPropagation()}
            >
              <Input
                type="checkbox"
                {...rowProps}
                indeterminate={(indeterminate ?? false).toString()}
                className="checked:accent-yellow-200 "
              />
            </div>
          )
        },
      },
      {
        Header: 'Name',
        accessor: 'product_name',
        filter: 'ilike',
        gfilter: 1,
        sortable: false,
        Cell: (props) => props.value?.substring(0, 20) + '...',
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        filter: 'eq',
        gfilter: 2,
        sortable: false,
      },
      {
        Header: 'ASIN',
        accessor: 'asin',
        filter: 'eq',
        gfilter: 3,
        sortable: false,
      },
      {
        Header: 'Parent ASIN',
        accessor: 'parent_asin',
        filter: 'eq',
        gfilter: 4,
        sortable: false,
      },
      {
        Header: 'Documents',
        accessor: 'documents',
        Cell: ({ row }) => {
          const docs = row.original.product_gpsr_document || []
          return (
            <div className="flex items-center">
              {docs.length > 0 ? (
                <>
                  {docs.slice(0, 5).map((doc, index) => (
                    <TooltipProvider key={index}>
                      <Tooltip>
                        <TooltipTrigger>
                          <FileText className="icon-class w-6 h-6" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <span className="font-medium">File: </span>{' '}
                          {doc.filename}
                          <br />
                          <span className="font-medium">Document Type: </span>
                          {documentTypes.find((d) => d.value === doc.type)
                            ?.label || '-'}
                          <br />
                          <span className="font-medium">Status: </span>
                          {amazonStatusOptions.find(
                            (option) => option.value === doc.status_amazon
                          )?.label || '-'}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ))}
                  {docs.length > 5 && <span>...</span>}
                </>
              ) : (
                <Button
                  variant="link"
                  className="p-0 h-auto font-normal text-sm text-slate-400 dark:text-slate-400 dark:hover:text-slate-200 dark:focus:text-slate-200 hover:text-slate-800 focus:text-slate-800 focus:outline-none"
                >
                  Add Documents
                </Button>
              )}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div className="flex items-center">
            Amazon Status
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InfoIcon className="icon-class w-5 h-5 ml-1 cursor-pointer stroke-2" />
                </TooltipTrigger>
                <TooltipContent>
                  {amazonStatusOptions.map((option, index) => (
                    <div key={index} className="mb-1">
                      <span className="font-medium">{option.label}: </span>
                      <span className="font-normal">
                        {option.description || 'No description available'}
                      </span>
                    </div>
                  ))}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
        accessor: 'amazon_status',
        sortable: false,
        Cell: ({ row }) => {
          const docs = row.original.product_gpsr_document || []
          const status = docs.map((doc) => doc.status_amazon)
          const uniqueStatus = [...new Set(status)]
          return (
            <div className="flex items-center">
              {uniqueStatus.map((s, i) => {
                const { label, className } = getBadgeAmazonStatusProps(
                  s,
                  amazonStatusOptions
                )
                return (
                  <Badge key={i} className={`${className} mr-1`}>
                    {label}
                  </Badge>
                )
              })}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div className="flex items-center">
            Amazon Issue
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InfoIcon className="icon-class w-5 h-5 ml-1 cursor-pointer stroke-2" />
                </TooltipTrigger>
                <TooltipContent className="max-w-sm">
                  <span className="font-normal">
                    Hover over the document issue icon(s) to view detailed
                    information about specific issues
                  </span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
        accessor: 'amazon_issue',
        sortable: false,
        Cell: ({ row }) => {
          const docs = row.original.product_gpsr_document || []
          const invalidDocs = docs.filter(
            (doc) => doc.status_amazon === 'INVALID'
          ) // Filter docs with status INVALID

          return (
            <div className="flex items-center">
              {invalidDocs.map((doc, index) => (
                <TooltipProvider key={index}>
                  <Tooltip>
                    <TooltipTrigger>
                      <FileWarning className="text-red-400 w-6 h-6" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-md">
                      <span className="font-medium">File: </span> {doc.filename}
                      <br />
                      <span className="font-medium">Document Type: </span>
                      {documentTypes.find((d) => d.value === doc.type)?.label ||
                        '-'}
                      <br />
                      <span className="font-medium">Issue: </span>
                      <pre className="break-words whitespace-pre-wrap">
                        {JSON.stringify(doc.issues, null, 2)}{' '}
                        {/* Pretty print JSON */}
                      </pre>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ))}
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        Cell: ({ row }) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onSelect={(e) => {
                  setSelectedRow(row)
                  setProductToAssociate(row?.original)
                }}
              >
                <Plus className="h-4 w-4 mr-2" />
                Add documents
              </DropdownMenuItem>
              <DropdownMenuItem
                disabled={!row?.original?.product_gpsr_document.length}
                onSelect={() => {
                  setSelectedRow(row)
                  setRowToEdit(row.original)
                }}
              >
                <Pencil className="h-4 w-4 mr-2" />
                Edit documents
              </DropdownMenuItem>
              <DropdownMenuItem
                disabled={!row?.original?.product_gpsr_document.length}
                onClick={(e) => {
                  e.stopPropagation()
                  deleteProductWarning(row?.original?.public_id)
                }}
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Remove documents
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ],
    []
  )

  return (
    <>
      {tableLoading ? (
        <Card className="overflow-visible h-auto w-full">
          <CardContent className="pt-6 overflow-visible">
            <div className="space-y-4">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-3/4" />
              <Skeleton className="h-4 w-1/2" />
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
            </div>
          </CardContent>
        </Card>
      ) : isAssociateProduct || isEditAssociateProduct ? (
        <Card className="w-full lg:w-[700px]">
          <CardContent className="px-6 py-4 pb-8">
            <form onSubmit={handleProductAssociation}>
              <div className="space-y-4">
                <CardTitle className="text-2xl font-space-grotesk font-normal tracking-normal">
                  {isEditAssociateProduct
                    ? 'Edit Associated Documents'
                    : 'Associate Documents with Products'}
                </CardTitle>

                <div>
                  <Label htmlFor="asin">ASIN</Label>
                  <MultiSelect
                    options={asin}
                    selectedOptions={selectedAsin}
                    onOptionSelect={(option) =>
                      setSelectedAsin((prev) => [...(prev || []), option])
                    }
                    onOptionUnselect={(option) =>
                      setSelectedAsin((prev) =>
                        (prev || []).filter(
                          (asin) => asin.value !== option.value
                        )
                      )
                    }
                    placeholder="Select ASIN(s)..."
                  />
                  {errors.asin && (
                    <span className="text-sm text-red-400 mt-1">
                      {errors.asin}
                    </span>
                  )}
                </div>

                <div>
                  <Label htmlFor="gpsr-document-select">GPSR Documents</Label>
                  <MultiSelect
                    options={gpsrDocuments}
                    selectedOptions={selectedGpsrDocuments}
                    onOptionSelect={(option) =>
                      setSelectedGpsrDocuments((prev) => [
                        ...(prev || []),
                        option,
                      ])
                    }
                    onOptionUnselect={(option) =>
                      setSelectedGpsrDocuments((prev) =>
                        (prev || []).filter((doc) => doc.value !== option.value)
                      )
                    }
                    placeholder="Select GPSR documents..."
                  />
                  {errors.gpsrDocument && (
                    <span className="text-sm text-red-400 mt-1">
                      {errors.gpsrDocument}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex justify-end mt-4 gap-4">
                <Button
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault()
                    setIsAssociateProduct(false)
                    setIsEditAssociateProduct(false)
                    // resetFieldInputs()
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" className="min-w-20">
                  {loadingAction ? (
                    <LoadingSpinner className="w-5 h-5" />
                  ) : isEditAssociateProduct ? (
                    'Update'
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      ) : (
        <Card className="">
          <CardContent className="px-6 py-4 relative">
            <div className="flex flex-col gap-4">
              <div>
                <CardTitle className="text-2xl font-space-grotesk font-normal tracking-normal">
                  Manage Products
                </CardTitle>
                <CardDescription className="font-normal">
                  Associate your compliance documents to your products and
                  monitor their submission status on Amazon.
                </CardDescription>
              </div>
              <div className="flex justify-end mb-4 absolute right-6">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="outline"
                      disabled={!selectedRows.length}
                      className="flex items-center gap-2"
                    >
                      Bulk Actions
                      <ChevronDown className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="min-w-[9rem] w-max max-w-[14rem]">
                    {bulkOptions.map((opt, i) => (
                      <DropdownMenuItem
                        key={i}
                        onSelect={() => handleBulkProductAssociation(opt.value)}
                      >
                        {opt.label}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <CustomTable
                columns={columns}
                data={products}
                loading={loading}
                total={total}
                pageCount={pageCount}
                queryParam={queryParam}
                setQueryParam={setQueryParam}
                onRowClick={(row, e) => {
                  setSelectedRow(row)
                  setProductToAssociate(row?.original)
                  // setProduct(
                  //   goals.find(
                  //     (g) => g.product_sku === row.original['product_sku']
                  //   )
                  // )
                  // history.push('/admin/goals/' + row.original['public_id'])
                }}
                filterOptions={filterOptions}
                filterStates={filterStates}
                setSelectedRows={setSelectedRows}
                hiddenColumns={['parent_asin']}
                searchInputPlaceholder="Search by Name, ASIN, Parent ASIN or SKU..."
                tableSearchKey="compliance_products"
              />
            </div>
          </CardContent>
        </Card>
      )}
      {deleteProductWarningAlert ? (
        <Dialog
          open={!!deleteProductWarningAlert}
          onOpenChange={deleteProductWarningHideAlert}
        >
          <DialogContent className="w-[380px] rounded-lg sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-space-grotesk font-normal flex items-center gap-2">
                Are you sure?
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="mb-4">
              <p className="font-regular">
                This action cannot be undone. Are you sure you want to
                disassociate all documents from this product?
              </p>
            </DialogDescription>
            <DialogFooter className="sm:justify-end">
              <Button
                variant="outline"
                onClick={deleteProductWarningHideAlert}
                className="mt-4 sm:mt-0"
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={() =>
                  deleteProductWarningConfirm(deleteProductWarningAlert)
                }
                disabled={isDeleting}
                className="min-w-[120px]"
              >
                {isDeleting ? <LoadingSpinner /> : 'Yes, remove!'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  )
}

export default React.memo(ManageProducts)
