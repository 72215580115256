import React, { useContext } from 'react'
import { X } from 'lucide-react'

import { Button } from 'shadcn-components/ui/button'
import { GPSR_MARKETPLACES, documentTypes } from 'variables/general'

import { FacetedFilter } from './faceted-filter'
import FilterContext from 'services/providers/FilterContext'
import TableSearchContext from 'services/providers/TableSearchContext'

export const docTypes = documentTypes
export const marketplaces = GPSR_MARKETPLACES.map((item) => ({
  value: item.id,
  label: item.country,
}))

export function FilterToolbar({
  searchValue,
  setSearchValue,
  filtersObject,
  filterStates,
  setQueryParam,
  queryParam,
  tableSearchKey = 'default',
}) {
  const { clearFilterQueryParams } = useContext(FilterContext)
  const { searchQueryParams } = useContext(TableSearchContext)

  const isFiltered = Object.values(filterStates).some(
    (state) => state.values.size > 0
  )

  const handleReset = () => {
    // Reset all filter states
    Object.values(filterStates).forEach((state) => {
      state.setValues(new Set())
    })

    // Reset query params but keep pagination and search params
    const newParams = {
      page: queryParam.page,
      per_page: queryParam.per_page,
      ...(searchQueryParams[tableSearchKey] || {}),
    }

    setQueryParam(newParams)
    clearFilterQueryParams()
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {Object.entries(filtersObject).map(([key, filter]) => (
          <FacetedFilter
            key={key}
            filterKey={key}
            title={filter.title}
            options={filter.options}
            selectedValues={filterStates[key].values}
            setSelectedValues={filterStates[key].setValues}
            setQueryParam={setQueryParam}
            queryParam={queryParam}
          />
        ))}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={handleReset}
            className="h-8 px-4 py-5 lg:px-3"
          >
            Reset
            <X className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  )
}
