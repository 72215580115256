import React, { useState, useEffect, useContext } from 'react'
import { Card, CardContent } from 'shadcn-components/ui/card'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'shadcn-components/ui/tabs'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import { Alert, AlertTitle, AlertDescription } from 'shadcn-components/ui/alert'
import ThemeContext from 'services/providers/ThemeContext'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import Documents from './Documents'
import ManageProducts from './ManageProducts'
import apiSellers from 'services/api/Seller'
import apiCompliance from 'services/api/Compliance'
import { GPSR_MARKETPLACES, documentTypes } from 'variables/general'
import { LockIcon, ExternalLink } from 'lucide-react'

const Compliance = ({ user }) => {
  const { lightMode } = useContext(ThemeContext)
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [primarySeller, setPrimarySeller] = useState([])
  const primarySellerPublicId = primarySeller?.public_id
  const [gpsrOptions, setGpsrOptions] = useState([])
  const [gpsrDocuments, setGpsrDocuments] = useState([])
  const [products, setProducts] = useState([])
  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: 10,
  })
  const [gfilterQuery, setGfilterQuery] = useState(false)
  const [pages, setPages] = useState(1)
  const [gpsrTotal, setGpsrTotal] = useState(0)
  const [productsTotal, setProductsTotal] = useState(0)
  const [selecteGpsrRow, setSelectedGpsrRow] = useState(null)
  const [selecteProductRow, setSelectedProductRow] = useState(null)
  const [selectedProductRows, setSelectedProductRows] = useState([])

  const queryClient = useQueryClient()

  const upgradedToGPSR = (user) => {
    // Add company public IDs here
    const upgradedCompanies = ['0462b7b8-7ac5-4bac-9b80-313792ed50b7'] // SimpleIda Dev
    return upgradedCompanies.includes(user?.company?.public_id) || false
  }

  const marketplaces = GPSR_MARKETPLACES.map((item) => ({
    label: item.country,
    value: item.id,
  }))

  const [horizontalTabs, setHorizontalTabs] = useState(() => {
    const segments = location.pathname.split('/').filter(Boolean)
    return segments[segments.length - 1] === 'compliance'
      ? 'documents'
      : segments.pop()
  })

  useEffect(() => {
    setPrimarySeller(user.primary_seller)
  }, [user])

  const mapGpsr = (data) => {
    return data.map((item) => {
      const type = documentTypes.find((x) => x.value === item.type)
      return {
        ...item,
        type: type?.label || item.type,
        type_value: type?.value || item.type,
        marketplace_value: item.marketplace.map((x) => x.value),
        marketplace: item.marketplace.map((x) => x.label).join(', '),
      }
    })
  }

  const setDocument = (gpsrDocObj, param) => {
    const obj = mapGpsr(gpsrDocObj?.data?.gpsr)
    setGpsrDocuments(obj)
    setPages(gpsrDocObj?.data.pages)
    setGpsrTotal(gpsrDocObj?.data.total)
    setGfilterQuery(
      Object.keys(param).some(function (k) {
        return ~k.indexOf('gfilter')
      })
    )
  }

  const {
    data: gpsrDocObj,
    isFetching: gpsrIsLoading,
    gpsrRefetch,
  } = useQuery(
    ['GET/gpsr-document/seller/', primarySellerPublicId, queryParam],
    () => apiCompliance.gpsr_documents(primarySellerPublicId, queryParam),
    {
      staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (gpsrDocObj) => {
        setDocument(gpsrDocObj, queryParam)
        setLoading(false)
      },
    }
  )

  useEffect(() => {
    const gpsrDocumentCache = queryClient.getQueryData([
      'GET/gpsr-document/seller/',
      primarySellerPublicId,
      queryParam,
    ])
    if (gpsrDocumentCache && gpsrDocumentCache.data?.gpsr) {
      setDocument(gpsrDocumentCache, queryParam)
      setLoading(false)
    }
  }, [queryClient, primarySellerPublicId, queryParam])

  const {
    data: gpsrOptionsObj,
    isFetching: gpsrOptionsIsLoading,
    gpsrOptionsRefetch,
  } = useQuery(
    ['GET/gpsr-document/seller/', primarySellerPublicId, '/options'],
    () => apiCompliance.gpsr_document_options(primarySellerPublicId),
    {
      staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (gpsrOptionsObj) => {
        const obj = mapGpsr(gpsrOptionsObj?.data?.gpsr)
        setGpsrOptions(obj)
        setLoading(false)
      },
    }
  )

  useEffect(() => {
    const gpsrDocOptionsCache = queryClient.getQueryData([
      'GET/gpsr-document/seller/',
      primarySellerPublicId,
      '/options',
    ])
    if (gpsrDocOptionsCache && gpsrDocOptionsCache.data?.gpsr) {
      const obj = mapGpsr(gpsrDocOptionsCache.data.gpsr)
      setDocument(gpsrDocOptionsCache, queryParam)
      setGpsrOptions(obj)
      setLoading(false)
    }
  }, [queryClient, primarySellerPublicId])

  const setProductStates = (pObj, param) => {
    setProducts(pObj?.data.products)
    setPages(pObj?.data.pages)
    setProductsTotal(pObj?.data.total)
    setGfilterQuery(
      Object.keys(param).some(function (k) {
        return ~k.indexOf('gfilter')
      })
    )
  }

  const {
    data: productsObject,
    isFetching: productIsLoading,
    productsRefetch,
  } = useQuery(
    ['GET/sellers/', primarySellerPublicId, '/products-gpsr', queryParam],
    () => apiSellers.productsGpsr(primarySellerPublicId, queryParam),
    {
      staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (productsObject) => {
        setProductStates(productsObject, queryParam)
        setLoading(false)
      },
    }
  )
  useEffect(() => {
    const productsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/products-gpsr',
      queryParam,
    ])
    if (productsCache && productsCache.data?.products) {
      setProductStates(productsCache, queryParam)
      setLoading(false)
    }
  }, [queryClient, primarySellerPublicId, queryParam])

  return (
    <>
      <div className="py-8 px-2 w-[96%] mx-auto">
        <div className="flex flex-col items-start w-full">
          <div className="flex flex-col gap-1 mb-6 w-full">
            <h2
              className={`text-3xl tracking-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              GPSR Compliance
            </h2>
            <p className="text-muted-foreground font-geist">
              Automate product compliance with General Product Safety
              Regulations (GPSR) across the EU.
            </p>
            {!upgradedToGPSR(user) && (
              <Alert className="mt-4 mb-1 bg-lime-200 border-lime-300 text-slate-800 dark:bg-transparent dark:border-lime-200 dark:text-lime-200">
                <LockIcon className="h-4 w-4 text-slate-800 dark:text-lime-200" />
                <AlertTitle>Upgrade</AlertTitle>
                <AlertDescription>
                  Unlock GPSR automations to streamline compliance, making it
                  easy to achieve and maintain compliance while safeguarding
                  your EU listings.{' '}
                  <a
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=GPSR%20Compliance%20Details%20and%20Pricing%20-%20Seller:%20${primarySeller?.selling_partner_id}&cc=jamie@complygpsr.com`}
                    target="_blank"
                    className="alert-link"
                  >
                    <span className="hover:underline font-semibold">
                      Contact the Autron team today
                      <ExternalLink className="ml-1 h-4 w-4 -translate-y-[.10rem] inline" />
                    </span>
                  </a>{' '}
                  for more details and pricing.
                </AlertDescription>
              </Alert>
            )}
          </div>
          {loading ? (
            <Card className="overflow-visible h-auto w-full">
              <CardContent className="pt-6 overflow-visible">
                <div className="space-y-4">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-3/4" />
                  <Skeleton className="h-4 w-1/2" />
                  <div className="grid grid-cols-3 gap-4 pt-4">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                  <div className="grid grid-cols-3 gap-4 pt-4">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                  <div className="grid grid-cols-3 gap-4 pt-4">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <Tabs
              value={horizontalTabs}
              onValueChange={(value) => setHorizontalTabs(value)}
              className="w-full"
            >
              <TabsList className="w-[300px]">
                <TabsTrigger
                  value="documents"
                  onClick={() => history.push('/admin/compliance/documents')}
                  className="w-1/2"
                >
                  Documents
                </TabsTrigger>
                <TabsTrigger
                  value="manage-products"
                  onClick={() =>
                    history.push('/admin/compliance/manage-products')
                  }
                  className="w-1/2"
                >
                  Manage Products
                </TabsTrigger>
              </TabsList>
              <TabsContent value="documents">
                <Documents
                  seller={primarySeller}
                  gpsrDocuments={gpsrDocuments}
                  documentTypes={documentTypes}
                  marketplaces={marketplaces}
                  loading={gpsrIsLoading}
                  total={gpsrTotal}
                  pageCount={pages}
                  queryParam={queryParam}
                  setQueryParam={setQueryParam}
                  gfilterQuery={gfilterQuery}
                  selectedRow={selecteGpsrRow}
                  setSelectedRow={setSelectedGpsrRow}
                  tableLoading={loading}
                />
              </TabsContent>
              <TabsContent value="manage-products">
                <ManageProducts
                  seller={primarySeller}
                  products={products}
                  documents={gpsrOptions}
                  marketplaces={marketplaces}
                  loading={productIsLoading}
                  total={productsTotal}
                  pageCount={pages}
                  queryParam={queryParam}
                  setQueryParam={setQueryParam}
                  gfilterQuery={gfilterQuery}
                  selectedRow={selecteProductRow}
                  setSelectedRow={setSelectedProductRow}
                  selectedRows={selectedProductRows}
                  setSelectedRows={setSelectedProductRows}
                  tableLoading={loading}
                />
              </TabsContent>
            </Tabs>
          )}
        </div>
      </div>
    </>
  )
}

export default Compliance
