import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

const url = 'product-gpsr-document'

const apiProductGpsrDocument = new ApiCore({
  createSingle: true,
  updateSingle: true,
  url: url,
  post: true,
})

apiProductGpsrDocument.associate_documents = (
  params = {},
  withCredentials = true
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/associate`, {
      params: params,
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiProductGpsrDocument.bulk_associate_documents = (
  params = {},
  withCredentials = true
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/bulk`, {
      params: params,
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiProductGpsrDocument.remove_association = (id, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/${id}/disassociate`, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

export default apiProductGpsrDocument
