import React, { createContext, useState, useCallback } from 'react'

const TableSearchContext = createContext(null)

export const TableSearchProvider = ({ children }) => {
  const [searchValues, setSearchValues] = useState({})
  const [searchQueryParams, setSearchQueryParams] = useState({})

  const updateSearchValue = useCallback((key, value) => {
    setSearchValues((prev) => ({
      ...prev,
      [key]: value,
    }))
  }, [])

  const updateSearchQueryParams = useCallback((key, params) => {
    setSearchQueryParams((prev) => ({
      ...prev,
      [key]: params,
    }))
  }, [])

  const clearSearchValues = useCallback(() => {
    setSearchValues({})
    setSearchQueryParams({})
  }, [])

  return (
    <TableSearchContext.Provider
      value={{
        searchValues,
        searchQueryParams,
        updateSearchValue,
        updateSearchQueryParams,
        clearSearchValues,
      }}
    >
      {children}
    </TableSearchContext.Provider>
  )
}

export default TableSearchContext
