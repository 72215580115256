import Dashboard from 'views/Dashboard.js'
import Register from 'views/pages/Register.js'
import Login from 'views/pages/Login.js'
import ForgotPassword from 'views/pages/ForgotPassword.js'
import ResetPassword from 'views/pages/ResetPassword.js'
import VerifyEmail from 'views/pages/VerifyEmail.js'
import Onboarding from 'views/onboarding/Onboarding.js'
import OnboardingSeller from 'views/onboarding/OnboardingSeller.js'
import Account from 'views/pages/account/Account.js'
import Products from 'views/products/Products.js'
import Goals from 'views/goals/Goals.js'
import Creatives from 'views/creatives/Creatives.js'
import Partners from 'views/partners/Partners.js'
import Affiliate from 'views/affiliates/Affiliate'
import Insights from 'views/insights/Insights.js'
import PPCAudit from 'views/pages/PPCAudit'
import PPCAuditResult from 'components/PPCAuditResult/PPCAuditResult'
import Review from 'views/reviews/Reviews'
import Compliance from 'views/compliance/Compliance'

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: '',
    icon: 'tim-icons icon-chart-pie-36',
    component: Dashboard,
    layout: '/admin',
    sidebar: true,
  },
  {
    collapse: true,
    name: 'Pages',
    rtlName: '',
    icon: 'tim-icons icon-image-02',
    state: 'pagesCollapse',
    sidebar: false,
    views: [
      {
        path: '/login',
        name: 'Login',
        rtlName: '',
        mini: 'L',
        rtlMini: '',
        component: Login,
        layout: '/auth',
        sidebar: false,
      },
      {
        path: '/register',
        name: 'Register',
        rtlName: '',
        mini: 'R',
        rtlMini: '',
        component: Register,
        layout: '/auth',
        sidebar: false,
      },
      {
        path: '/register-founding-seller',
        name: 'Register',
        rtlName: '',
        mini: 'R',
        rtlMini: '',
        component: Register,
        layout: '/auth',
        sidebar: false,
      },
      {
        path: '/forgot-password',
        name: 'Forgot Password',
        rtlName: '',
        mini: 'FP',
        rtlMini: '',
        component: ForgotPassword,
        layout: '/auth',
        sidebar: false,
      },
      {
        path: '/reset-password',
        name: 'Reset Password',
        rtlName: '',
        mini: 'RP',
        rtlMini: '',
        component: ResetPassword,
        layout: '/auth',
        sidebar: false,
      },
      {
        path: '/verify-email',
        name: 'Verify Email',
        rtlName: '',
        mini: 'VE',
        rtlMini: '',
        component: VerifyEmail,
        layout: '/auth',
        sidebar: false,
      },
    ],
  },
  {
    collapse: true,
    name: 'Onboarding',
    rtlName: '',
    icon: 'tim-icons icon-pin',
    state: 'onboardingCollapse',
    sidebar: false,
    views: [
      {
        path: '/onboarding/seller/:public_id?',
        name: 'Seller',
        rtlName: '',
        mini: 'OS',
        rtlMini: '',
        component: OnboardingSeller,
        layout: '/admin',
        sidebar: false,
      },
      {
        path: '/onboarding',
        name: 'Onboarding',
        rtlName: '',
        mini: 'O',
        rtlMini: '',
        component: Onboarding,
        layout: '/admin',
        sidebar: false,
      },
    ],
  },
  {
    path: '/insights/:type?',
    name: 'Insights',
    rtlName: '',
    icon: 'tim-icons icon-chart-bar-32',
    component: Insights,
    layout: '/admin',
    sidebar: true,
  },
  {
    path: '/goals/:public_id?',
    name: 'Goals',
    rtlName: '',
    icon: 'tim-icons icon-spaceship',
    component: Goals,
    layout: '/admin',
    sidebar: true,
  },
  {
    path: '/creatives/:type?/:public_id?',
    name: 'Creatives',
    rtlName: '',
    icon: 'tim-icons icon-video-66',
    component: Creatives,
    layout: '/admin',
    sidebar: true,
  },
  {
    path: '/products/:public_id?',
    name: 'Products',
    rtlName: '',
    icon: 'tim-icons icon-app',
    component: Products,
    layout: '/admin',
    sidebar: true,
  },
  {
    path: '/reviews',
    name: 'Reviews',
    rtlName: '',
    icon: 'tim-icons icon-shape-star',
    component: Review,
    layout: '/admin',
    sidebar: true,
    new: true,
  },
  {
    path: '/compliance',
    name: 'Compliance',
    rtlName: '',
    icon: 'tim-icons icon-shape-star',
    component: Compliance,
    layout: '/admin',
    sidebar: true,
    new: true,
  },
  {
    path: '/account',
    name: 'Account',
    rtlName: '',
    icon: 'tim-icons icon-single-02',
    component: Account,
    layout: '/admin',
    sidebar: true,
  },
  {
    path: '/partners',
    name: 'Partners',
    rtlName: '',
    icon: 'tim-icons icon-world',
    component: Partners,
    layout: '/admin',
    sidebar: true,
  },
  {
    path: '/affiliates',
    name: 'Earn',
    rtlName: '',
    icon: 'tim-icons icon-coins',
    component: Affiliate,
    layout: '/admin',
    sidebar: true,
  },
  {
    path: 'ppc-audit',
    name: 'PPCAudit',
    rtlName: '',
    icon: 'tim-icons icon-gift-2',
    component: PPCAudit,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'ppc-audit/results',
    name: 'PPCAudit',
    rtlName: '',
    icon: 'tim-icons icon-gift-2',
    component: PPCAuditResult,
    layout: '/',
    sidebar: false,
  },
]

export default routes
