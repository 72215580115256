import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Fragment,
} from 'react'

import { Button } from 'shadcn-components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'shadcn-components/ui/dropdown-menu'
import { Input } from 'shadcn-components/ui/input'
import { Label } from 'shadcn-components/ui/label'
import { Alert, AlertDescription, AlertTitle } from 'shadcn-components/ui/alert'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import Goal from './Goal'
import apiGoals from 'services/api/Goal'
import { useHistory, useParams, Link } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Badge } from 'shadcn-components/ui/badge'
import { ChevronDown, Pencil } from 'lucide-react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'
import {
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  CardContent,
} from 'shadcn-components/ui/card'
import CustomTable from 'components/CustomTable/CustomTable'
import { useToast } from 'shadcn-components/ui/use-toast'

const GoalsByProduct = ({
  objectives,
  strategies,
  type,
  goals,
  globalGoal,
  newProducts,
  seller,
  profileId,
  isFreeUser,
  loading,
  total,
  pageCount,
  queryParam,
  setQueryParam,
  gfilterQuery,
  selectedRows,
  setSelectedRows,
  selectedRow,
  setSelectedRow,
  activeTab,
}) => {
  const history = useHistory()
  const params = useParams()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const [currency, setCurrency] = useState('')
  const [creatingGoals, setCreatingGoals] = useState(false)
  const [goal, setGoal] = useState()
  const [showGoalForm, setShowGoalForm] = useState(!!params.public_id)

  useEffect(() => {
    if (seller?.primary_marketplace_currency_code) {
      setCurrency(seller.primary_marketplace_currency_code)
    }
  }, [seller])

  useEffect(() => {
    if (params.public_id) {
      const foundGoal = goals.find((g) => g.public_id === params.public_id)
      if (foundGoal) {
        setGoal(foundGoal)
        setSelectedRow({ original: foundGoal })
      }
    }
    setShowGoalForm(!!params.public_id)
  }, [params.public_id, goals])

  useEffect(() => {
    if (selectedRow && selectedRows.length === 0) {
      setGoal(
        goals.find((g) => g.product_sku === selectedRow.original['product_sku'])
      )
    } else if (selectedRows.length != 0) {
      const selectedProducts = mapProducts(selectedRows)

      // find the 1st item on the selectedProducts on the goals list to extract defaults for bulk operation
      const firstProduct = goals.find(
        (g) => g.product_sku === selectedProducts[0].sku
      )

      if (firstProduct.type === 'product') {
        setGoal({
          ...firstProduct,
          products: selectedProducts,
          isBulk: true,
        })
      } else {
        setGoal({
          objective: globalGoal.objective,
          strategy: globalGoal.strategy,
          strategy_value: globalGoal.strategy_value,
          use_global_public_id: globalGoal.public_id,
          state: 'enabled',
          products: selectedProducts,
          isBulk: true,
        })
      }
    }
    setShowGoalForm(!!params.public_id)
  }, [params])

  const mapProducts = (data) => {
    return data.map(
      ({
        product_asin: asin,
        public_id: goal_public_id,
        product_price: price,
        product_profit: profit,
        product_public_id: public_id, // product_public_id
        product_sku: sku,
        product_your_price: your_price,
      }) => {
        return {
          asin,
          goal_public_id,
          price,
          profit,
          public_id,
          sku,
          your_price,
        }
      }
    )
  }

  const handleProcessSelectedRows = useCallback(() => {
    const selectedProducts = mapProducts(selectedRows)

    // find the 1st item on the selectedProducts on the goals list to extract defaults for bulk operation
    const firstProduct = goals.find(
      (g) => g.product_sku === selectedProducts[0].sku
    )

    if (firstProduct.type === 'product') {
      setGoal({
        ...firstProduct,
        products: selectedProducts,
        isBulk: true,
      })
    } else {
      setGoal({
        objective: globalGoal.objective,
        strategy: globalGoal.strategy,
        strategy_value: globalGoal.strategy_value,
        use_global_public_id: globalGoal.public_id,
        state: 'enabled',
        products: selectedProducts,
        isBulk: true,
      })
    }

    history.push(`/admin/goals/${globalGoal.public_id}`)
  }, [selectedRows, globalGoal, history])

  const handleBulkAction = useCallback(
    (e) => {
      if (e === 'update') {
        handleProcessSelectedRows()
      }
    },
    [handleProcessSelectedRows]
  )

  const handleGoalEdit = (goalData) => {
    setGoal(goalData)
    history.push(
      `/admin/goals/${goalData.public_id}${
        activeTab ? `?tab=${activeTab}` : ''
      }`
    )
  }

  const bulkOptions = [{ value: 'update', label: 'Update goals' }]

  const createGoalsClick = async (e, product_asins) => {
    const goalData = {
      seller_selling_partner_id: seller.selling_partner_id,
      marketplace_marketplace_id: seller.primary_marketplace_id,
      profile_profile_id: profileId,
      product_asins: product_asins,
    }
    setCreatingGoals(true)
    const response = await apiGoals.createDefaultGoals(goalData)
    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/sellers/')
      toast({
        variant: 'success',
        description: response?.data?.message,
      })
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
    }
    setCreatingGoals(false)
  }

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div className="flex items-center space-x-2">
            <Input
              type="checkbox"
              {...getToggleAllRowsSelectedProps()}
              className="checked:accent-yellow-200 "
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div
            className="flex items-center space-x-2"
            onClick={(e) => e.stopPropagation()}
          >
            <Input
              type="checkbox"
              {...row.getToggleRowSelectedProps()}
              className="checked:accent-yellow-200 "
            />
          </div>
        ),
      },
      {
        Header: 'Name',
        accessor: 'product_name',
        filter: 'ilike',
        gfilter: 4,
        sortable: false,
        Cell: (props) => props.value?.substring(0, 100) + '...',
      },
      {
        Header: 'SKU',
        accessor: 'product_sku',
        filter: 'eq',
        gfilter: 2,
        sortable: false,
      },
      {
        Header: 'ASIN',
        accessor: 'product_asin',
        filter: 'eq',
        gfilter: 1,
        sortable: false,
      },
      {
        Header: 'Parent ASIN',
        accessor: 'product_parent_asin',
        filter: 'eq',
        gfilter: 3,
        sortable: false,
      },
      {
        Header: 'Product Price',
        accessor: 'product_price',
        sortable: false,
      },
      {
        Header: 'Product Profit',
        accessor: 'product_profit',
        sortable: false,
      },
      {
        Header: 'Product your Price',
        accessor: 'product_your_price',
        sortable: false,
      },
      {
        Header: 'Product Public Id',
        accessor: 'product_public_id',
        sortable: false,
      },
      {
        Header: 'Type',
        accessor: 'use_global_public_id',
        Cell: (props) => (
          <Badge
            variant="outline"
            className={`capitalize ${
              props.value
                ? 'border-yellow-100 bg-yellow-300 text-white dark:border-yellow-200 dark:bg-yellow-100 dark:text-slate-900'
                : 'border-yellow-300 bg-yellow-50 text-yellow-400 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200'
            }`}
          >
            {props.value ? 'global' : 'custom'}
          </Badge>
        ),
      },
      {
        Header: 'Objective',
        accessor: 'objective',
        Cell: (props) => objectives.find((o) => o.value === props.value)?.label,
      },
      {
        Header: 'Strategy',
        accessor: 'strategy',
        Cell: (props) =>
          strategies.find((s) => s.value === props.value)?.label || null,
      },
      {
        Header: 'Target',
        accessor: 'strategy_value',
        Cell: (props) => {
          const strategy = strategies.find(
            (s) => s.value === props.row.original.strategy
          )
          return strategy ? strategy.value_format(props.value, currency) : null
        },
      },
      {
        Header: 'Turbo Mode',
        accessor: 'turbo_mode',
        Cell: (props) => (
          <span className="capitalize">
            {' '}
            {props.value === 'enabled' ? 'on' : 'off'}{' '}
          </span>
        ),
      },
      {
        Header: 'Status',
        accessor: 'state',
        Cell: (props) => (
          <Badge
            variant="outline"
            className={`capitalize ${
              props.value === 'enabled'
                ? 'border-lime-300 bg-lime-50 text-lime-400 dark:border-lime-200 dark:bg-transparent dark:text-lime-300'
                : 'border-red-300 bg-red-50 text-red-400 dark:border-red-200 dark:bg-transparent dark:text-red-300'
            }`}
          >
            <span className="uppercase">
              {props.value === 'enabled' ? 'on' : 'off'}
            </span>
          </Badge>
        ),
      },
      {
        Header: 'Actions',
        Cell: (props) => (
          <div className="flex justify-end">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={() =>
                      handleGoalEdit(
                        goals.find(
                          (g) =>
                            g.product_sku === props.row.original.product_sku
                        )
                      )
                    }
                    variant="ghost"
                    size="icon"
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Edit</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
      },
    ],
    [currency, history]
  )

  const hiddenColumns = [
    'product_parent_asin',
    'product_sku',
    'product_price',
    'product_profit',
    'product_your_price',
    'product_public_id',
  ]

  return (
    <>
      {goals?.length > 0 ||
      (goals?.length === 0 && gfilterQuery) ||
      newProducts?.length ? (
        showGoalForm ? (
          <Goal
            objectives={objectives}
            strategies={strategies}
            type={'product'}
            goal={goal}
            goalGlobalPublicId={globalGoal.public_id}
            products={
              goal?.isBulk
                ? goal?.products
                : mapProducts(
                    goals.filter((g) => g?.product_sku === goal?.product_sku)
                  )
            }
            seller={seller}
            profileId={profileId}
            isFreeUser={isFreeUser}
            dataIsLoading={false}
            setSelectedRow={setSelectedRow}
            setSelectedRows={setSelectedRows}
          />
        ) : isFreeUser ? (
          <Card className="">
            <CardContent className="px-6 py-4 relative">
              <Fragment>
                <CardHeader>
                  <CardTitle>Product Goals</CardTitle>
                  <CardDescription>
                    Upgrade to leverage Autron's Ad Engine across all your
                    products.
                  </CardDescription>
                </CardHeader>
                <Button className="mt-4" asChild>
                  <Link to="/admin/account/billing">Upgrade</Link>
                </Button>
              </Fragment>
            </CardContent>
          </Card>
        ) : (
          <Card className="">
            <CardContent className="px-6 py-4 relative flex flex-col gap-4">
              <div>
                <CardTitle className="text-2xl font-space-grotesk font-normal tracking-normal">
                  Product Goals
                </CardTitle>
                <p className="text-sm text-muted-foreground">
                  Customize goals for specific products or groups, using bulk
                  actions for streamlined setup.{' '}
                </p>
                {newProducts?.length > 0 && (
                  <Alert className="mt-4 mb-1 bg-yellow-100 border-yellow-200 dark:bg-transparent dark:border-yellow-100">
                    <AlertDescription className="flex flex-wrap gap-3 items-center">
                      <span>
                        <b>New Products Detected</b> - let's create goals for
                        these too
                      </span>
                      <Button onClick={(e) => createGoalsClick(e, newProducts)}>
                        {creatingGoals ? (
                          <LoadingSpinner className="mr-2" />
                        ) : null}
                        Create Goals
                      </Button>
                    </AlertDescription>
                  </Alert>
                )}
              </div>

              <div>
                <div className="flex justify-end mb-4 absolute right-6">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        disabled={!selectedRows.length}
                        className="flex items-center gap-2"
                      >
                        Bulk Actions
                        <ChevronDown className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="min-w-[9rem] w-max max-w-[12rem]">
                      {bulkOptions.map((opt, i) => (
                        <DropdownMenuItem
                          key={i}
                          onSelect={() => handleBulkAction(opt.value)}
                        >
                          {opt.label}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div className="mt-16 sm:mt-0">
                  <CustomTable
                    columns={columns}
                    data={goals}
                    loading={loading}
                    total={total}
                    pageCount={pageCount}
                    queryParam={queryParam}
                    setQueryParam={setQueryParam}
                    onRowClick={(row, e) => {
                      setSelectedRow(row)
                      const goalData = goals.find(
                        (g) => g.product_sku === row.original['product_sku']
                      )
                      setGoal(goalData)
                      history.push(`/admin/goals/${row.original['public_id']}`)
                    }}
                    setSelectedRows={setSelectedRows}
                    hiddenColumns={hiddenColumns}
                    tableSearchKey="goals_by_product"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        )
      ) : goals?.length === 0 && !gfilterQuery ? (
        <Card className="w-full">
          <CardContent className="px-6 py-4 relative">
            <Alert>
              <AlertDescription>
                Create a global goal, which will act as a default, before
                defining Product specific goals.
              </AlertDescription>
            </Alert>
          </CardContent>
        </Card>
      ) : null}
    </>
  )
}

export default GoalsByProduct
