import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// User API
const url = 'sellers'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiSellers = new ApiCore({
  post: true,
  deleteSingle: true,
  getSingle: true,
  url: url,
})

apiSellers.primary = (id, data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .put(`/${url}/${id}/primary`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiSellers.products = (id, params = {}, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/${id}/products`, {
      params: params,
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiSellers.productFinancials = (id, params = {}, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/${id}/product-financials`, {
      params: params,
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiSellers.goals = (id, params = {}, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/${id}/goals`, {
      params: params,
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiSellers.newGoals = (id, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/${id}/goals-new`, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiSellers.brands = (id, params = {}, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/${id}/brands`, {
      params: params,
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiSellers.refresh = (id, data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .put(`/${url}/${id}/amazon-relationships`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiSellers.search = (searchTerm, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/search`, {
      params: { search_term: searchTerm },
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiSellers.getSeller = (id, params = {}) => {
  return axios
    .get(`/${url}/ppc-audit/${id}`, {
      params: params,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiSellers.productsGpsr = (id, params = {}, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/${id}/products-gpsr`, {
      params: params,
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

export default apiSellers
