import React, { useState, useRef, useEffect, useContext } from 'react'

import { Card, CardContent } from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { Progress } from 'shadcn-components/ui/progress'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { Check, Upload } from 'lucide-react'

import ThemeContext from 'services/providers/ThemeContext'
import { useToast } from 'shadcn-components/ui/use-toast'

const UploadThumbnailGallery = ({
  assets,
  assetType,
  onPick,
  onUpload,
  uploading = false,
  updating = false,
  videoURL = '',
}) => {
  const { toast } = useToast()
  const fileInput = useRef(null)
  const [file, setFile] = useState(null)
  const [videoSrc, setVideoSrc] = useState('')
  const [videoMeta, setVideoMeta] = useState({})
  const [progress, setProgress] = useState(videoURL ? 100 : 0) // dodge
  const { lightMode } = useContext(ThemeContext)

  const sortedAssets = assets // .sort((a, b) => (a.picked === b.picked)? 0 : a? -1 : 1)

  const onThumbnailClick = (key) => {
    setPicked(picked.map((p, i) => (true ? key === i : false)))
  }

  useEffect(() => {
    if (file && videoMeta) {
      onUpload(file, videoMeta)
    }
  }, [videoMeta])

  useEffect(() => {
    if (assetType === 'video') {
      if (!file && !videoURL) {
        setVideoSrc(undefined)
        return
      }
      const objectUrl = videoURL ? videoURL : URL.createObjectURL(file)
      setVideoSrc(objectUrl)
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
    } else {
      if (file) {
        onUpload(file)
      }
    }
  }, [file, assetType])

  function readerErrorHandler(evt) {
    switch (evt.target.error.code) {
      case evt.target.error.NOT_FOUND_ERR:
        toast({
          variant: 'destructive',
          description: 'File not found!',
        })
        break
      case evt.target.error.NOT_READABLE_ERR:
        toast({
          variant: 'destructive',
          description: 'File is not readable',
        })
        break
      case evt.target.error.ABORT_ERR:
        break // noop
      default:
        toast({
          variant: 'destructive',
          description: 'An error occurred reading this file',
        })
    }
  }

  const handleAssetChange = (e) => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onerror = readerErrorHandler
    reader.onprogress = (e) =>
      setProgress(Math.round((e.loaded * 100) / e.total))
    reader.onabort = (e) =>
      toast({
        variant: 'destructive',
        description: 'File read cancelled',
      })
    reader.onloadend = () => {
      setFile(file)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
    fileInput.current.value = '' // reset so fileInput onChange triggers even if SAME file selected
  }

  const [picked, setPicked] = useState(
    sortedAssets ? sortedAssets.map((i) => i.picked) : []
  )

  const Image = ({ url, picked }) => {
    const [loaded, setLoaded] = useState(false)
    return (
      <>
        <div className="relative flex justify-center items-center h-28 w-28 shadow-md rounded-sm transition-all duration-300 ease-in-out hover:shadow-lg">
          <img
            className="p-2"
            src={url}
            alt=""
            onLoad={() => setLoaded(true)}
          />
          {!picked && (
            <div className="absolute inset-0 flex items-center justify-center bg-slate-800  bg-opacity-50 opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100 rounded-sm">
              <span className="text-white text-center">
                Set as
                <br />
                default
              </span>
            </div>
          )}
          {picked && (
            <div className="absolute top-2 right-2 bg-lime-500 rounded-full p-1">
              <Check className="w-4 h-4 text-slate-800" />
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="space-y-4">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 sm:gap-3">
          {assetType === 'video' && progress > 0 && (
            <Card className="col-span-full sm:col-span-2">
              <CardContent className="p-3">
                <div className="aspect-w-16 aspect-h-9">
                  {file && file.type === 'video/quicktime' ? (
                    <div className="absolute inset-0 flex items-center justify-center  bg-opacity-50 text-white">
                      Cannot preview quicktime or .mov videos
                    </div>
                  ) : null}
                  {/* {videoURL && (
                    <div className="absolute inset-0 bg-black bg-opacity-50" />
                  )} */}
                  <video
                    controls={
                      (file && file.type !== 'video/quicktime') || videoURL
                    }
                    muted
                    autoPlay
                    src={videoSrc}
                    className="w-full h-full object-cover rounded-lg"
                    controlsList="nodownload"
                    onLoadedMetadata={(e) => {
                      setVideoMeta({
                        videoHeight: e.target.videoHeight,
                        videoWidth: e.target.videoWidth,
                        duration: e.target.duration,
                      })
                    }}
                  />
                </div>
                {progress === 100 ? (
                  !videoURL && (
                    <Button
                      variant="outline"
                      onClick={(e) => {
                        e.preventDefault()
                        fileInput.current.click()
                      }}
                      className="mt-3"
                    >
                      Change video
                    </Button>
                  )
                ) : (
                  <Progress value={progress} className="w-full" />
                )}
              </CardContent>
            </Card>
          )}
          <Card
            className={`${
              assetType === 'video' && progress > 0 ? 'hidden' : ''
            } w-[8rem] border-dotted border-gray-600`}
          >
            <CardContent className="p-2 h-full flex justify-center items-center">
              <div
                className="relative flex items-center justify-center rounded-lg cursor-pointer overflow-hidden"
                onClick={() => fileInput.current?.click()}
              >
                <input
                  type="file"
                  accept={
                    assetType === 'image'
                      ? 'image/*'
                      : assetType === 'video'
                      ? 'video/mp4,video/x-m4v,video/quicktime'
                      : assetType === 'csv'
                      ? 'text/csv'
                      : ''
                  }
                  onChange={handleAssetChange}
                  ref={fileInput}
                  className="hidden"
                />
                {uploading ? (
                  <LoadingSpinner className="w-4 h-4" />
                ) : (
                  <div className="aspect h-20 w-20 text-center flex flex-col justify-center items-center">
                    <Upload className="h-8 w-8 text-slate-300" />
                    <p className="font-medium text-muted-foreground">Upload</p>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
          {sortedAssets?.map((asset, key) => (
            <div
              onClick={() => {
                onPick(asset)
                onThumbnailClick(key)
              }}
              className="cursor-pointer w-[7rem]"
              key={key}
            >
              <Image url={asset.url} picked={picked[key]} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default UploadThumbnailGallery
